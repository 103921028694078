import React,{useState,useEffect} from 'react'
import { Toolbar,Grid,Box,Avatar,IconButton,Badge, Tooltip, Button,Icon, TextField, Typography} from '@mui/material'
import {styled} from '@mui/material/styles';
import logo from '../../assets/images/logo.png';
import { APPDrawer } from './drawer';
import { stringAvatar } from '../../common/utility/helperFunc';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import RupeeIcon from '@mui/icons-material/CurrencyRupee';
// import HelpIcon from '@mui/icons-material/HelpCenter';
import HelpIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import BookmarkIcon from '@mui/icons-material/Bookmarks';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION, USER_ROLE_FIELDS, USER_RESOURCES_LIST } from '../../common/utility/constant';
import { Snackbar, Alert } from '@mui/material';
import useAuth from '../../common/hooks/useAuth';
import SubscriptionPopup from '../../pages/dashboard/components/subscription_popup';
import { BOOKMARK_COMP } from '../../common/utility/configData';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserManualPopup from '../../pages/dashboard/components/user_manual_pop_up';
import DemoPopup from '../../pages/dashboard/components/demo_popup';
import PlayCircleOutlinedIcon from '@mui/icons-material/YouTube';
import FAQPopup from '../../pages/dashboard/components/faq_pop_up';
import YTImg from '../../assets/images/youtube.png';
import BrokerPopup from '../../pages/dashboard/components/broker_popup';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import BookmarkComponent from './BookmarkComponent';




const HeaderToolbar=styled(Toolbar)(
  ({theme})=> `
  background-color:${theme.palette.primaryTheme.shade07};
  width:100vw;
  height:48px;
  position:fixed;
  z-index:999;
  ${theme.breakpoints.up('mobile')}{
    min-height:48px;
  }
  `
)

const DashboardHeader = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { cancel,responseData, error, loaded, reset,executeAPI } = useAxios();
  const [showPopup,setShowPopup]=useState(false)
  const [showProPopup,setShowProPopup]=useState(false)
  const matches = useMediaQuery('(min-width:640px)');
  const [subscriptionPopupData,setSubscriptionPopupData]=useState({isCustom:false,msg:""});
  const [showUserManualPopup,setShowUserManualPopup]=useState(false);
  const [showDemoPopup,setShowDemoPopup]=useState(false);
  const [showFaqPopup,setShowFaqPopup]=useState(false);
  const [showBrokerPopup,setShowBrokerPopup]=useState(false);
    
  
  
  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg,severity } = msgState;
  const { auth } = useAuth();
  const from = "/login";
  const [cancelBookmark,responseDataBookmark, errorBookmark, loadedBookmark, resetBookmark,executeAPIBookmark ] = useAxiosWithAuth();
  const isEliteUser = (auth.role.find(el => el == USER_ROLE_FIELDS.PRO) && auth.resources.find(el => el == USER_RESOURCES_LIST.TBT_FEED))
  const [bookmarkList,setBookmarkList]=useState([]);
  

  console.log("Auth object=",auth);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  

  useEffect(()=>{
    if(isEliteUser){
      executeAPIBookmark(URL.GET_ALL_GLOBAL_BOOKMARK,"GET",{})
    }
  },[])

    //api response handler
    useEffect(()=>{
      if(loadedBookmark){
        // console.log("Bookmark list ========>",responseData.data);
        if(responseDataBookmark!=null){
          let data=responseDataBookmark.data?responseDataBookmark.data:[];
         setBookmarkList(data);
       
          
         
         }else if(errorBookmark!==null){
         
  
          console.log("Error data=",errorBookmark);
          
         
          // setMsgState({open:true,msg:errorAutoSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          if(errorBookmark?.response?.status === 401 || errorBookmark?.response?.status === 403){
            console.log("status received =",errorBookmark?.response?.status)
            navigate(from, { replace: true });
          }else{
            setMsgState({open:true,msg:errorBookmark?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          }
        }
        resetBookmark();
        
      }
  
    },[loadedBookmark,responseDataBookmark])
 /**
 * API response handler  
 */  
     useEffect(() => {
      if(loaded){
        if(responseData!=null){
          navigate('/login', { replace: true });
        }
        else if(error!==null){
            setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`
          ,severity:"info"});
          
          reset();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[loaded,responseData,error,navigate]);
  
  
  
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setSelection=(route,component)=>{
    console.log("Route=",route);
    props.setCurrentRoute(route,component);
  }

  const openSettings=()=>{
    navigate('/settings', { replace: true });
  }

  const openNotifications=()=>{
    navigate('/notifications', { replace: true });
  }

  const openFaq=()=>{
    setShowFaqPopup(true);
    // navigate('/faq', { replace: true });
  }

  const openUserManual=()=>{
    // navigate('/user-manual', { replace: true });
    // setShowDemoPopup(false);
    setShowUserManualPopup(true);
  }

  const openDemoPopup=()=>{
    // setShowUserManualPopup(false);
    setShowDemoPopup(true);
  }

  const openGlobalBookmark=()=>{
    if(!isEliteUser){
      setSubscriptionPopupData({isCustom:true,msg:TEXT_MSGS.ELITE_UPGRADE_MSG});
      setShowProPopup(true);
    }
    else
    navigate('/global-bookmarks', { replace: true });
  }
  
  const openProfile=()=>{
    setAnchorEl(null);
    navigate('/profile', { replace: false });
  }

  const handleLogout=()=>{
    setAnchorEl(null);
    executeAPI(URL.LOGOUT,"POST",null); 
 
  }

  const showSubscribeModal=()=>{
    setShowPopup(true);
  }

  const showSubscribeProModal=(isCustom=false,msg)=>{
    if(isCustom){
      setSubscriptionPopupData({isCustom:true,msg:msg});
      setShowProPopup(true);
    }else{
      setSubscriptionPopupData({isCustom:false,msg:""});
      setShowProPopup(true);

    }
  }
  const showBrokerLoginPopup=()=>{
    setShowBrokerPopup(true)
  }
  

  const handleBookmarkClick = (bookmark) => {
    console.log("Clicked on:", bookmark);
  };
  return (
    <div>
        <HeaderToolbar >
            <Grid  container >
                <Grid item mobile={2} tablet={2} laptop={2} >
                  <Box display="flex" justifyContent="flex-start">
                    <APPDrawer onChange={setSelection} openBrokerModal={showBrokerLoginPopup} openSubscribeModal={showSubscribeModal} openSubscribeProModal={showSubscribeProModal}/>
                    {props.headerData && matches?
                 <Typography  sx={{color:'primaryTheme.shade01',fontSize:"20px",ml:6,fontWeight:700,pt:1.5}}><u>{props.headerData.name}</u></Typography>:
                 <></>
                  }
                 </Box>
                
                </Grid>
                
                <Grid item mobile={4} tablet={4} laptop={8} >
                  
                  <Box display="flex" sx={{height:"100%"}} justifyContent="center" alignItems="center">
                 
                    <img src={logo} alt="Vtrender Logo" width={120} height={30}/>
                  </Box>
                </Grid>
                
                <Grid item mobile={6} tablet={6} laptop={2} >
                  
                  <Box display="flex" height="100%" justifyContent="flex-end"  alignItems={"center"} sx={{mr:0}}>
                   <> 
                   {matches?
                  <><Button variant="contained" href='/subscription' sx={{mr:2,minWidth:"90px",textAlign:"center"}}>
                    Pricing <RupeeIcon sx={{width:"18px",height:"18px"}}/>
                </Button>
                <Button variant="contained" onClick={() => openDemoPopup()} sx={{mr:2,minWidth:"90px",textAlign:"center",ml:0}} 
              
                endIcon={(<img src={YTImg} style={{width:22,height:22,marginLeft:-6}}/>)}
                >
                    Demo&nbsp; 
                    {/* <PlayCircleOutlinedIcon sx={{width:"22px",height:"24px",color:"red"}}/> color:'#CD201F',background:'white' */}
                </Button></>:<>
                <Tooltip title="Demo Video"> 
                        <Button
                        onClick={() => openDemoPopup()}
                        sx={{marginRight:"10px",minWidth:"32px"}}
                        size='small'
                        edge='start'
                        endIcon={(<img src={YTImg} style={{width:24,height:24,marginLeft:-4}}/>)}
                        aria-label='settings'>
                         
                    </Button>
                    </Tooltip>
                    </>}

                  {/* <IconButton
                        onClick={() => openBookmarks()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="Custom Bookmarks"> 
                          <BookmarkIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton> */}
                    {/* <IconButton
                        onClick={() => openNotifications()}
                        sx={{mr:1}}
                        size='large'
                        edge='start'
                        aria-label='settings'>
                        <Badge badgeContent={4} color="error" variant="dot">
                          <NotificationsIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                        </Badge>
                    </IconButton> */}
                    {/* <IconButton
                        onClick={() => openDemoPopup()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="Demo Video"> 
                          <PlayCircleOutlinedIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton> */}
                    <IconButton
                        onClick={() => openUserManual()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="User Manual"> 
                          <HelpIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton>
                    
                    {matches?
                    <>
                     {/* <IconButton
                        onClick={() => openDemoPopup()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="Demo Video"> 
                          <PlayCircleOutlinedIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton> */}
                    {/* <IconButton
                        onClick={() => openUserManual()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="User Manual"> 
                          <HelpIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton> */}
                    <IconButton
                        onClick={() => openFaq()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="Glossary of Terms"> 
                          <InfoIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton>
                    </>:<></>}
                    {matches?
                    <BookmarkComponent bookmarks={bookmarkList} onBookmarkClick={handleBookmarkClick} />:
                    <></>
                    }
                    {/* <IconButton
                        onClick={() => openSettings()}
                        sx={{mr:1}}
                        size='small'
                        edge='start'
                        aria-label='settings'>
                          <Tooltip title="User Settings"> 
                          <SettingsIcon sx={{color:'primaryTheme.shade01',width:"24px",height:"24px"}}/>
                          </Tooltip>
                    </IconButton> */}
                    
                    <Avatar  id="basic-button"
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                      onClick={handleClick} {...stringAvatar(auth.name)} />
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        sx={{minWidth:"140px"}}
                        disableGutters={true}
                        MenuListProps={{
                        'aria-labelledby': 'user-avatar',
                        }}>
                        <MenuItem sx={{pl:4,pr:4}} onClick={openProfile}>{"Profile"}</MenuItem>
                        <MenuItem sx={{pl:4,pr:4}} onClick={handleLogout}>{"Logout"}</MenuItem>
                    </Menu>
                  </>
                  </Box>
                </Grid>
            </Grid>
        </HeaderToolbar>
        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
      <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
         {msg}
       </Alert>  
     </Snackbar> 
     
     {showPopup?
         <SubscriptionPopup open={showPopup} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
     }
     {showProPopup?
         <SubscriptionPopup open={showProPopup} data={subscriptionPopupData} proMessage={true} onClose={()=>setShowProPopup(false)}></SubscriptionPopup>  :
         <></>
      }
      {showUserManualPopup?
      <UserManualPopup open={showUserManualPopup}  onClose={()=>setShowUserManualPopup(false)}/>:
      <></> 
  }
   {showDemoPopup?
      <DemoPopup open={showDemoPopup}  onClose={()=>setShowDemoPopup(false)}/>:
      <></> 
  }
   {showFaqPopup?
      <FAQPopup open={showFaqPopup}  onClose={()=>setShowFaqPopup(false)}/>:
      <></> 
  }

{showBrokerPopup?
      <BrokerPopup open={showBrokerPopup} role={auth.role}  onClose={()=>setShowBrokerPopup(false)}/>:
      <></> 
  }

    </div>
  )
}

export default DashboardHeader
