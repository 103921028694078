import { useEffect, useRef } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = ({ position, color, onChange, onClose }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        onClose(); // Close the picker if clicked outside
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose(); // Close the picker on ESC key
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return (
    <div
      ref={pickerRef}
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        zIndex: 10000,
        background: "#fff",
        // padding: "10px",
        // borderRadius: "8px",
        // boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <ChromePicker color={color} onChange={onChange} />
    </div>
  );
};

export default ColorPicker;
