import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography,Select,MenuItem,FormControl,InputLabel,Checkbox,useTheme,Box } from "@mui/material";
import { BOOKMARKT_CATEGORY_DATA ,BOOKMARK_NAME_MAX_LENGTH,BOOKMARK_CATEGORY_MAX_LENGTH} from "../../../common/utility/constant";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


export default function SaveGlobalBookmarkDialog(props) {
  const theme=useTheme();
  const [open, setOpen] = React.useState(props.open);
  const [title, setTitle] = React.useState(props.name?props.name:"");
 
  

  const handleClose = () => {
    // setOpen(false);
    props.cancelHandler();
  };

  const handleSave = () => {
    console.log("handleSave=",title);
    props.saveHandler(title);
    setOpen(false);
   
  };

 
  const handleTitleChange=(event)=>{
    setTitle(event.target.value)
   
  }
  return (
    <div>
     
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{props.title?props.title:"Save Bookmark"}</DialogTitle>
        <DialogContent sx={{width:"440px",ml:0,mr:2 }}>
            <FormControl size="small" sx={{ width:"350px",minWidth:'80px'}}>
          <TextField
            autoFocus
            required
            variant="outlined"
            value={title}
            sx={{mt:4,minWidth:400}}
            onChange={(event) => handleTitleChange(event)}
            inputProps={{ maxLength: BOOKMARK_NAME_MAX_LENGTH }}
            // margin="dense"
            id="name"
            label="Title"
            // error={title .trim()=== ""}
            // helperText={title.trim() === "" ? 'Bookmark title is required' : ' '}
            // fullWidth
            
          /> 
        
        </FormControl>
  
    
        </DialogContent>
        <DialogActions>
       
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={title.trim()==""} sx={{mr:2}}onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
