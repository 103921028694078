
import React,{useContext,useEffect,useState} from 'react'
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,SNACKBAR_AUTO_HIDE_DURATION_SHORT } from '../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel } from '@mui/material';
import {Grid, Box,Typography,Select,ListSubheader,TextField,InputAdornment,FormControl,MenuItem,Popover,Divider} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './components/settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE } from '../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme} from '@mui/material/styles';
import { BOOKMARK_CATEGORY_AUTOSAVE } from '../../common/utility/constant';
import LoginModal from './components/login_popups';

const Bookmarks = () => {
  const theme=useTheme();
  const from = "/login";
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelDelete,responseDataDelete, errorDelete, loadedDelete, resetDelete,executeAPIDelete ] = useAxiosWithAuth();
  const [bookmarkState, setBookmarkState]=useState({
    bookmarkData:undefined,
    displayData:undefined,
    isLoaded:false
  });
  const [deletedId,setDeletedId]=useState();

  const {bookmarkData,displayData,isLoaded}=bookmarkState;
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  
  /**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Market Profile Bookmarks";
  }, []);

  //load bookmarks
  useEffect(()=>{
    executeAPI(URL.GET_BOOKMARK_LIST,"GET",{})
  },[])

   //api response handler
   useEffect(()=>{
    if(loaded){
      console.log("Bookmark list ========>",responseData.data);
      if(responseData!=null){
        let data=responseData.data.filter(item => item.category!=BOOKMARK_CATEGORY_AUTOSAVE);
        // let data=responseData.data;
        setBookmarkState((prevState)=>({
          ...prevState,
          bookmarkData:data,
          displayData:data,
          isLoaded:true
        }))
       }else if(error!==null){
        setBookmarkState((prevState)=>({
          ...prevState,
          isLoaded:true
        }))

        console.log("Error data=",error);
        
       
        // setMsgState({open:true,msg:errorAutoSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        if(error?.response?.status === 401 || error?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
      }
      reset();
      
    }

  },[loaded,responseData])

  const deleteBookmark=(id)=>{
    let data={
      "id":id
    }
    setDeletedId(id);
    executeAPIDelete(URL.DELETE_BOOKMARK,"DELETE",data)
  }

  //delete api response handler
  useEffect(()=>{
    if(loadedDelete){
      console.log("Delete response ========>",responseDataDelete);
      if(responseDataDelete!=null){
        setMsgState({open:true,msg:`${TEXT_MSGS.BOOKMARK_DELETED_SUCCESSFULLY}`,severity:"success"});
        let updatedData= bookmarkData.filter(item => item.id!=deletedId);
        localStorage.setItem("bookmark_list",JSON.stringify(updatedData));
        setBookmarkState((prevState)=>({
          ...prevState,
          bookmarkData:updatedData,
          displayData:updatedData,
          isLoaded:true
        }))
        setDeletedId(undefined);
       
       }else if(errorDelete!==null){
        setBookmarkState((prevState)=>({
          ...prevState,
          isLoaded:true
        }))
        setDeletedId(undefined);

       
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        if(errorDelete?.response?.status === 401 || errorDelete?.response?.status === 403){
          console.log("status received =",errorDelete?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorDelete?.response?.data?.message);
          setShowLoginPopup(true);
        }else
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetDelete();
      
    }

  },[loadedDelete,responseDataDelete])


  const copyURL =(event,id)=>{
    const url=`${process.env.REACT_APP_URL}market-profile?bookmarkID=${id}`
    navigator.clipboard.writeText(url);
    setAnchorEl(event.currentTarget);
  }

  const openBookmark=(event,id)=>{
      const url=`${process.env.REACT_APP_URL}market-profile?bookmarkID=${id}`
      window.open( `${url}`, '_blank', 'noreferrer');
    
  }

  return (
   <>

   {isLoaded?
   <Box display="flex" flexDirection="column" sx={{m:4}}>
    <Typography sx={{mt:0,fontSize:20}}>Market Profile Bookmarks</Typography>
    {bookmarkData.length>0?
    <Box sx={{mt:4}}>
      <Box display='flex' sx={{mb:2}}>
           <Typography sx={{mt:0,fontSize:18,width:400}}>Name</Typography>
           <Typography sx={{mt:0,ml:2,fontSize:18,width:240}}>Category</Typography>
           <Typography sx={{mt:0,ml:2,fontSize:18,width:160,paddingTop:"0px"}}>Last Updated</Typography>
           </Box>
       {displayData.map((item,index) => (
        <Box display='flex' sx={{height:70,borderBottom:"1px solid #888",mt:2}}>
          <Box sx={{width:400}}>
           <Typography noWrap sx={{mt:0,fontSize:16,width:300}}>{item.name}</Typography>
           <Box display='flex'>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>Instrument:&nbsp;{item.data.instrument},&nbsp;</Typography>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>Time Frame:&nbsp;{item.data.tf},&nbsp;</Typography>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>TPO:&nbsp;{item.data.tpo}</Typography>
           </Box>
           </Box>
           <Box sx={{width:240,ml:2}}>
           <Typography noWrap sx={{mt:1,fontSize:16,width:240}}>{item.category}</Typography>
           </Box>
           <Box sx={{width:160,ml:2}}>
           <Typography sx={{mt:1,fontSize:14,width:160}}>{item.last_saved}</Typography>
           </Box>
           <DeleteIcon onClick={()=>deleteBookmark(item.id)} sx={{color: "#e24747",cursor:'pointer',ml:4,mt:1}}></DeleteIcon>
           <Tooltip title="Open in new tab">
           <Button onClick={(event)=>openBookmark(event,item.id)} variant='outlined' sx={{ml:2,mr:2,marginTop:"0px",color:"#fff",height:40,width:110}}>Open</Button>
           </Tooltip>
           <Button onClick={(event)=>copyURL(event,item.id)} variant='outlined' sx={{ml:2,mr:2,marginTop:"0px",color:"#fff",height:40,width:110}}>Copy URL</Button>
           <Popover  id={id} open={openPopover} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{vertical: 'top',horizontal: 'center'}}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
      >
        <Typography sx={{ p: 2 }}>URL copied to clipboard.</Typography>
      </Popover>

      <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
        </Box>            
                         
       ))}
    </Box>:
    <Typography sx={{mt:4,fontSize:16}}>There is no bookmark present.</Typography>
    }
    
   </Box>
   :
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>} 

    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  
       {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}   
   </>
  )
}

export default Bookmarks
