import React, { useState, useEffect, useMemo,useContext } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { UserSettingsContext } from "../../../setup/routes-manager/RequireAuth";


const disabledDateFactory = (tradeDates) => {
  // For "date" type: disable if the full date string is not in tradeDates
  const disableSpecificDate = (current) => {
    if (!current) return false;
    const formatted = current.format("YYYY-MM-DD");
    return !tradeDates.includes(formatted);
  };

  // For "month" type: enable the month if at least one date in the month is available in tradeDates
  const disableMonth = (current) => {
    if (!current) return false;
    // Get the start and end of the month
    const startOfMonth = current.startOf('month');
    const endOfMonth = current.endOf('month');
    let hasAvailableDay = false;
    let day = startOfMonth;

    // Loop through each day of the month
    while (day.isBefore(endOfMonth) || day.isSame(endOfMonth, 'day')) {
      const formatted = day.format("YYYY-MM-DD");
      if (tradeDates.includes(formatted)) {
        hasAvailableDay = true;
        break;
      }
      day = day.add(1, 'day');
    }
    // If none of the days are available, disable the month.
    return !hasAvailableDay;
  };

  // For "year" type: enable the year if at least one month in that year has an available day
  const disableYear = (current) => {
    if (!current) return false;
    const startOfYear = current.startOf("year");
    const endOfYear = current.endOf("year");
    let hasAvailableMonth = false;
    let month = startOfYear;

    // Iterate month-by-month in the year
    while (month.isBefore(endOfYear) || month.isSame(endOfYear, "month")) {
      // Check if this month is enabled by our month-level logic
      if (!disableMonth(month)) {
        hasAvailableMonth = true;
        break;
      }
      month = month.add(1, "month");
    }
    return !hasAvailableMonth;
  };

  // Return a function that inspects info.type to decide which logic to run
  return (current, info) => {
    if (info?.type === "year") {
      return disableYear(current);
    }
    if (info?.type === "month") {
      return disableMonth(current);
    }
    // Default to date check
    return disableSpecificDate(current);
  };
};

//MP CC date time 
const TradeDatesPicker = (props) => {
  const [tradeDates, setTradeDates] = useState(props.tradeDates || []);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate||null);
  const { userSettings,setUserSettings, UIData } = useContext(UserSettingsContext);
  const disabledDate = useMemo(() => disabledDateFactory(tradeDates), [tradeDates]);


  
  
  // // Memoized function to disable specific dates
  // const disabledDate = useMemo((date,info) => {
  //   console.log("BOBO Datetest disabledDate=",date,info)
  //   return (current) => {
  //     const formattedCurrentDate = current ? current.format("YYYY-MM-DD") : null;
  //     return !tradeDates.includes(formattedCurrentDate);
  //   };
  // }, [tradeDates]);
  

  useEffect(()=>{
    console.log("DATETest CC 0",props.selectedDate,selectedDate)
    if(props.selectedTimeFrame){
      console.log("BOBO Datetest=",tradeDates,UIData)
    }
    
   },[])

   useEffect(()=>{
   
   setTradeDates(props.tradeDates)
    
   },[props.tradeDates])

 useEffect(()=>{
  console.log("DATETest CC 1",props.selectedDate,selectedDate)
  setSelectedDate(props.selectedDate);
 },[props.selectedDate])

  // Handle DatePicker change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("DATETest CC 2 Selected date:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    props.onChange(date);
  };

  // Handle DatePicker OK button
  const handleOk = (date) => {
    console.log("Date selected on OK:", date ? date.format("DD-MM-YYYY HH:mm") : null);
  };

  return (
    <DatePicker
      allowClear={false}
      format="DD-MM-YYYY"
      // disabledDate={(date,info)=>{
      //   console.log("BOBO Datetest disabledDate= date,info",date,info)
      //   if(info.type!='month')
      //   disabledDate(date,info)
      // }}
      disabledDate={disabledDate}
      placeholder="Select date"
      onChange={handleDateChange}
      onOk={handleOk}
      value={selectedDate}
      style={{
        border: "1px solid #415e81",
        background: "#022D5A",
        borderRadius: "4px",
        width: 160,
        height:28,
        marginLeft: 36,
      }}
    />
  );
};

export default TradeDatesPicker;
