import React, { useState,useEffect } from 'react'
import {Link, Grid ,Snackbar,Alert,Popover,CircularProgress,Checkbox,useTheme} from "@mui/material"
import Typography from '@mui/material/Typography';
import upiImg from '../../assets/images/VtrenderChartsUPI.png';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/DoneAll';
import PlusIcon from '@mui/icons-material/Add';
import ArrowIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RupeeIcon from '@mui/icons-material/CurrencyRupee';
import { URL } from '../../common/api/urls';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
// import bannerImg from '../../assets/images/diwali-banner4.jpeg';
// import bannerImg from '../../assets/images/hny-banner5.jpg';
import bannerImg from '../../assets/images/republic-banner1.jpg';
import miniBannerImg from '../../assets/images/mini-banner1.jpeg';
import useAuth from '../../common/hooks/useAuth';
import axios from 'axios';
import useRefreshToken from '../../common/hooks/useRefreshToken';
import { useNavigate, useLocation } from 'react-router-dom';
import { SUBSCRIPTION_PLAN_TYPE,SNACKBAR_AUTO_HIDE_DURATION_SHORT,TEXT_MSGS,PAYMENT_STATUS,SUBSCRIPTION_PLANS_PRICE_NSE,SUBSCRIPTION_PLANS,SUBSCRIPTION_PLANS_NSE } from '../../common/utility/constant';
import $ from 'jquery';
import Popper from '@mui/material/Popper';
import { Radio } from 'antd';
import LinkIcon from '@mui/icons-material/Link';
import OrderDetailPopup from './components/order_detail_popup';
import LoginModal from './components/login_popups';
import StarRateIcon from "@mui/icons-material/StarRate";
// import { Checkbox } from 'antd';

const cardSX={
  minWidth: 300,maxWidth:340,minHeight:1980,maxHeight:1980, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"0px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const cardSXPlan={
  minWidth: 300,maxWidth:340,minHeight:1980,maxHeight:1980, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"0px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const cardSXPlanOF={
  minWidth: 300,maxWidth:340,minHeight:1980,maxHeight:1980, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"0px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const cardEcourseSX={
  minWidth: 850,maxWidth:870,minHeight:160, display:"flex", flexDirection:'column',alignItems:'center',marginTop:"20px",pl:1,pr:1,backgroundColor:"#36455e",borderRadius:4 ,
  "&:hover": {
    // transition: "transform 0.15s ease-in-out",
    // transform: "scale3d(1.05, 1.05, 1)" 
  }
}

const buttonSX = {
 
  width:300,mb:1,fontSize:22,height:40,background:"#23B1BA",backgroundColor:"#23B1BA",
  "&:hover": {
    borderColor: "rgba(255,240,10,0.8)",
    backgroundColor:"#23B1BA",
    background:"#23B1BA",
    opacity:0.9,
   
  },
};
const buttonSXSmall = {
 
  width:30,height:24,mb:1,fontSize:14,background:"#23B1BA",backgroundColor:"#23B1BA",
  "&:hover": {
    borderColor: "rgba(255,240,10,0.8)",
    backgroundColor:"#23B1BA",
    background:"#23B1BA",
    opacity:0.9
  },
};

const buttonSXPlus= {
 
  width:300,mb:1,fontSize:22,height:40,background:"#E7B401",backgroundColor:"#E7B401",
  "&:hover": {
    backgroundColor:"#E7B401",
    background:"#E7B401",
    opacity:0.9
  },
};

const buttonSXOF= {
 
  width:300,mb:1,fontSize:22,height:40,background:"#EC6C10",backgroundColor:"#EC6C10",
  "&:hover": {
    backgroundColor:"#EC6C10",
    background:"#EC6C10",
    opacity:0.9
  },
};

const buttonSXOFInternational= {
 
  width:300,mb:1,fontSize:14,height:20,background:"#A1652E",backgroundColor:"#A1652E",
  "&:hover": {
    backgroundColor:"#A1652E",
    background:"#A1652E",
    opacity:0.9
  },
};

const buttonSXPlusSmall= {
 
  width:30,height:24,mb:1,fontSize:14,background:"#E7B401",backgroundColor:"#E7B401",
  "&:hover": {
    backgroundColor:"#E7B401",
    background:"#E7B401",
    opacity:0.9
  },
};

const buttonSXPlusMedium= {
 
  width:160,mb:1,fontSize:20,height:40,background:"#FBE103",backgroundColor:"#FBE103",
  "&:hover": {
    backgroundColor:"#FBE103",
    background:"#FBE103",
    opacity:0.9
  },
};

const imgStyle = {
  width: "60%",
  // height: "500px",
  // // align: "middle",
  // display:"flex",
  // justifyContent:"center",
  marginLeft: 20, 
  marginRight: 20, 
  marginTop: 0, 
  marginBottom: 20
}

const SUBSCRIPTION_MONTHLY_TYPE={
    MONTHLY:"monthly",
    QUARTERLY:"quarterly",
    HALF_YEARLY:"half_yearly",
    ANNUALLY:"annually"
}

const SUBSCRIPTION_MONTHLY_TYPE_TEXT={
    MONTHLY:"per Month / Billed Monthly",
    QUARTERLY:"for Quarterly Plan / Billed Quarterly",
    HALF_YEARLY:"for Half-yearly Plan / Billed Half-yearly",
    ANNUALLY:"for Annual Plan / Billed Annually"
}

const Subscription = () => {
  const from = "/login";
  const theme=useTheme();
  const navigate = useNavigate(); 
  const location = useLocation();
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
  const [cancelSubscription,responseDataSubcription,errorSubscription,loadedSubscription,resetSubscription,executeAPISubscription] = useAxiosWithAuth();
  const [cancelOrder,responseDataOrder,errorOrder,loadedOrder,resetOrder,executeAPIOrder] = useAxiosWithAuth();
  const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
  const [cancelOrderDetail,responseDataOrderDetail,errorOrderDetail,loadedOrderDetail,resetOrderDetail,executeAPIOrderDetail] = useAxiosWithAuth();
  const [type, setType] = useState(SUBSCRIPTION_MONTHLY_TYPE.MONTHLY);
  const [typeText, setTypeText] = useState(SUBSCRIPTION_MONTHLY_TYPE_TEXT.MONTHLY);
  const [period, setPeriod] = useState(1);
  const [monthlyDiscFactor, setMonthlyDiscFactor] = useState(1); // useState(0.85);
  const [yearlyDiscFactor, setYearlyDiscFactor] = useState(0.8); // useState(0.6);
  let monthlyDiscTxt = "";
  if(monthlyDiscFactor != 1) monthlyDiscTxt = " * "+monthlyDiscFactor;
  const [premiumTotal,setPremiumTotal]=useState(Math.round(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks_gst)));
  const [premiumIndexTotal,setPremiumIndexTotal]=useState(Math.round(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base_gst)));
  const [premiumPlusTotal,setPremiumPlusTotal]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks_gst)));
  const [premiumPlusIndexTotal,setPremiumPlusIndexTotal]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base_gst)));
  const [premiumPlusOFTotal,setPremiumPlusOFTotal]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks_gst)));
  const [premiumPlusOFIndexTotal,setPremiumPlusOFIndexTotal]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base_gst)));
  const [premiumPlusOFTotal_i,setPremiumPlusOFTotal_i]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks_i+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks_gst_i)));
  const [premiumPlusOFIndexTotal_i,setPremiumPlusOFIndexTotal_i]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base_i+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base_gst_i)));
 
  const [premiumTotalActual,setPremiumTotalActual]=useState(Math.round(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks_gst)));
  const [premiumIndexTotalActual,setPremiumIndexTotalActual]=useState(Math.round(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base_gst)));
  const [premiumPlusTotalActual,setPremiumPlusTotalActual]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks_gst)));
  const [premiumPlusIndexTotalActual,setPremiumPlusIndexTotalActual]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base_gst)));
  const [premiumPlusOFTotalActual,setPremiumPlusOFTotalActual]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks_gst)));
  const [premiumPlusOFIndexTotalActual,setPremiumPlusOFIndexTotalActual]=useState(Math.floor(monthlyDiscFactor*(SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base_gst)));
 


  const [premiumBreakup,setPremiumBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks+monthlyDiscTxt+" + "+Math.round(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.stocks_gst)+" GST)");
  const [premiumIndexBreakup,setPremiumIndexBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base+monthlyDiscTxt+" + "+Math.round(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].premium.base_gst)+" GST)");
  const [premiumPlusBreakup,setPremiumPlusBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.stocks_gst)+" GST)");
  const [premiumPlusIndexBreakup,setPremiumPlusIndexBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].expert.base_gst)+" GST)");
  const [premiumPlusOFBreakup,setPremiumPlusOFBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.stocks_gst)+" GST)");
  const [premiumPlusOFIndexBreakup,setPremiumPlusOFIndexBreakup]=useState("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[type].elite.base_gst)+" GST)");
 

  const [ecourseAlreadyBought,setEcourseAlreadyBought]=useState(false);
  const [stockVal,setStockVal]=useState(false);
  const [orderflowVal,setOrderflowVal]=useState(false);
  const [orderflowStockVal,setOrderflowStockVal]=useState(false);
  const [isPremiumUpgradeAllowed,setIsPremiumUpgradeAllowed]=useState(true);
  const [isPremiumPlusUpgradeAllowed,setIsPremiumPlusUpgradeAllowed]=useState(false);
  const [planName,setPlanName]=useState(undefined);
  const [showorderPopup,setShowOrderPopup]=useState(false);
  const [orderDetailsData,setOrderDetailsData]=useState();
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  const [isInternational,setIsInternational]=useState(false);
  const [amountDifference,setAmountDifference]=useState(0);
 

  const [subscriptionState,setSubscriptionState]=useState({
    isLoaded:false,
    isError:false,
    subsciptionData:undefined
  })

  const {isLoaded,isError, subsciptionData}=subscriptionState;

  const [userSubscriptionState,setUserSubscriptionState]=useState({
    isUserSubsLoaded:false,
    isErrorUserSubs:false,
    userSubsData:undefined
  })

  const {isUserSubsLoaded,userSubsData,isErrorUserSubs}=userSubscriptionState;

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Subscription";
  }, []);


 

 
  
 
  const handleClick = (planType,isIndex=false,isInternational=false) => {
    
    console.log("handleClick=",isPremiumUpgradeAllowed,planType,isIndex);
    let plan_name;
    let amtDifference=0;
    if(type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY){
        if(isPremiumUpgradeAllowed ){
          if(planType==SUBSCRIPTION_PLAN_TYPE.PREMIUM){
            if(isIndex)
                plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_MONTHLY_1;
            else {
              plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_STOCKS_MONTHLY_1;
                // setMsgState({open:true,msg:TEXT_MSGS.MP_STOCKS_ADD_ONS_NOT_SELECTED,severity:"info"});
                // return;
            }
          }
          else if(planType==SUBSCRIPTION_PLAN_TYPE.EXPERT){
            if(isIndex)
                plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_MONTHLY_1;
            else {
              plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_STOCKS_MONTHLY_1;
                // setMsgState({open:true,msg:TEXT_MSGS.MP_STOCKS_ADD_ONS_NOT_SELECTED,severity:"info"});
                // return;
            }
          }
          else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational==false){
            if(isIndex)
                plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1;
            else {
              plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1;
                // setMsgState({open:true,msg:TEXT_MSGS.MP_STOCKS_ADD_ONS_NOT_SELECTED,severity:"info"});
                // return;
            }
          }
          else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational){
            if(isIndex){
                plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_1_INTERNATIONAL; 
                amtDifference=premiumPlusOFIndexTotal_i-premiumPlusOFIndexTotal;
            }
            else {
              plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_1_INTERNATIONAL;
              amtDifference=premiumPlusOFTotal_i-premiumPlusOFTotal;
                // setMsgState({open:true,msg:TEXT_MSGS.MP_STOCKS_ADD_ONS_NOT_SELECTED,severity:"info"});
                // return;
            }
          }
        }
        
       

    }else if(type==SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY){
      if(planType==SUBSCRIPTION_PLAN_TYPE.PREMIUM){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_MONTHLY_3;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_STOCKS_MONTHLY_3;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.EXPERT){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_MONTHLY_3;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_STOCKS_MONTHLY_3;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational==false){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational){
        if(isIndex){
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_3_INTERNATIONAL;
            amtDifference=premiumPlusOFIndexTotal_i-premiumPlusOFIndexTotal;
        }
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_3_INTERNATIONAL;
          amtDifference=premiumPlusOFTotal_i-premiumPlusOFTotal;
        }
      }

    }else if(type==SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY){
      if(planType==SUBSCRIPTION_PLAN_TYPE.PREMIUM){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_MONTHLY_6;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_STOCKS_MONTHLY_6;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.EXPERT){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_MONTHLY_6;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_STOCKS_MONTHLY_6;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational==false){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational){
        if(isIndex){
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_6_INTERNATIONAL;
            amtDifference=premiumPlusOFIndexTotal_i-premiumPlusOFIndexTotal;
        }
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_6_INTERNATIONAL;
          amtDifference=premiumPlusOFTotal_i-premiumPlusOFTotal;
        }
      }
    }else if(type==SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY){
      if(planType==SUBSCRIPTION_PLAN_TYPE.PREMIUM){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_MONTHLY_12;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_STOCKS_MONTHLY_12;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.EXPERT){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_MONTHLY_12;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_STOCKS_MONTHLY_12;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational==false){
        if(isIndex)
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12;
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12;
        }
      }
      else if(planType==SUBSCRIPTION_PLAN_TYPE.ELITE && isInternational){
        if(isIndex){
            plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_MONTHLY_12_INTERNATIONAL;
            amtDifference=premiumPlusOFIndexTotal_i-premiumPlusOFIndexTotal;
        }
        else {
          plan_name=SUBSCRIPTION_PLANS_NSE.PREMIUM_PLUS_ORDERFLOW_INDEX_STOCKS_MONTHLY_12_INTERNATIONAL;
          amtDifference=premiumPlusOFTotal_i-premiumPlusOFTotal;
        }
      }
    }

    if(isInternational){
      setIsInternational(true);
      setAmountDifference(amtDifference);
    }else{
      setIsInternational(false);
      setAmountDifference(0);
    }
    console.log("Orderdetails= call 1 plan=",plan_name)
    setPlanName(plan_name);
    const data={"plan_name": plan_name,"status_check":1}
    executeAPIOrderDetail(URL.SUBSCRIPTION_ORDER,"POST",data)

   
  };
  

  

  const handleClickEcourse = (event) => {
    console.log("handleClickEcourse=");
    let plan_name=SUBSCRIPTION_PLANS.VMR_ECOURSE;
    setPlanName(plan_name);
    const data={"plan_name": plan_name,"status_check":1}
    executeAPIOrderDetail(URL.SUBSCRIPTION_ORDER,"POST",data)
  };

  

  /**
   * autoclosing of the snackbar msg bar 
   */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
  };

  
    //load subscription plans
    useEffect(()=>{
      executeAPISubscription(URL.USER_SUBSCRIPTION,"GET",{})
      executeAPI(URL.GET_SUBSCRIPTION_PLANS,"GET",{}); 
      // verifyRefreshToken();
    },[]);


  const processUserSubscription=(data)=>{
    console.log("processUserSubscription=",data);
    for(let i=0;i<data.length;i++){
        if((data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_MONTHLY_3 || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_MONTHLY_6 || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_MONTHLY_12)&& data[i].days_to_expiry>31){
            setIsPremiumUpgradeAllowed(true);
            setPremiumTotal(0);
            setPremiumBreakup("");
           
           
        }
        if((data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_PLUS_MONTHLY_3 || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_PLUS_MONTHLY_6 || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_PLUS_MONTHLY_12)&& data[i].days_to_expiry>31){
            setIsPremiumPlusUpgradeAllowed(true)
            setPremiumPlusTotal(0);
            setPremiumPlusBreakup("")
        }
        if(data[i].plan_name==SUBSCRIPTION_PLANS.VMR_ECOURSE || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_PLUS_ORDERFLOW_MONTHLY_12 || data[i].plan_name==SUBSCRIPTION_PLANS.PREMIUM_PLUS_ORDERFLOW_STOCKS_MONTHLY_12){
          setEcourseAlreadyBought(true);
        }
    }

  }  

    /**
   * API response handler subscription data
   */  
  useEffect(() => {
    if(loadedSubscription){
      if(responseDataSubcription!=null){
        processUserSubscription(responseDataSubcription.data.active_subscriptions);
        setUserSubscriptionState({
          isUserSubsLoaded:true,
          isErrorUserSubs:false,
          userSubsData:responseDataSubcription.data
        })
        
      }
      else if(errorSubscription!==null){
        setUserSubscriptionState({
          isUserSubsLoaded:true,
          isErrorUserSubs:true,
          userSubsData:undefined
        })
       
        setMsgState({open:true,msg:errorSubscription?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorSubscription?.response?.status === 401 || errorSubscription?.response?.status === 403){
          console.log("status received =",errorSubscription?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetSubscription();
    }
  },[loadedSubscription,responseDataSubcription]);


  /**
   * API response handler subscription data
   */  
  useEffect(() => {
    if(loaded){
      if(responseData!=null){
        setSubscriptionState({
          isLoaded:true,
          isError:false,
          subsciptionData:responseData.data
        })
        
      }
      else if(error!==null){
        setSubscriptionState({
          isLoaded:true,
          isError:true,
          subsciptionData:undefined
        })
       
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(error?.response?.status === 401 || error?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }
    }
    reset();
    }
  },[loaded,responseData]);



 
  /**
   * API response handler subscription data
   */  
  useEffect(() => {
    if(loadedOrder){
      if(responseDataOrder!=null){
        if(responseDataOrder.success!=undefined && responseDataOrder.success==true){
          // const redirect_url=`https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${responseDataOrder.encRequest}&access_code=${responseDataOrder.access_code}`
          // window.link=redirect_url;
          // window.location=redirect_url;

          console.log(responseDataOrder);

          let url = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
          if(responseDataOrder.type!=undefined && responseDataOrder.type=="test") url = 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction';
          let form = $('<form action="' + url + '" method="post">' +
                      '<input type="hidden" id="encRequest" name="dtype" value="'+ responseDataOrder.type+ '">'+
                      '<input type="hidden" id="encRequest" name="encRequest" value="'+ responseDataOrder.encRequest+ '">'+
                      '<input type="hidden" name="access_code" id="access_code" value="'+ responseDataOrder.access_code+ '">'+
                      '</form>');

          $('body').append(form);
          form.submit();
          
        }
        
        
      }
      else if(errorOrder!==null){
       
        //if unauthorized then redirec it to login page
        if(errorOrder?.response?.status === 401 || errorOrder?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorOrder?.response?.data?.message);
          setShowLoginPopup(true);
        }else
        setMsgState({open:true,msg:errorOrder?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
    }
    resetOrder();
    }
  },[loadedOrder,responseDataOrder]);

    /**
   * API response handler subscription data
   */  
    useEffect(() => {
        console.log("OrderDetails=",responseDataOrderDetail);
        if(loadedOrderDetail){
          if(responseDataOrderDetail!=null){
            if(responseDataOrderDetail.success!=undefined && responseDataOrderDetail.success==true){
                console.log("OrderDetails 1=",responseDataOrderDetail);
             setOrderDetailsData(responseDataOrderDetail)
             setShowOrderPopup(true);
              
            }
            
            
          }
          else if(errorOrderDetail!==null){
            
            //if unauthorized then redirec it to login page
            if(errorOrderDetail?.response?.status === 401 || errorOrderDetail?.response?.status === 403){
              console.log("status received =",errorOrderDetail?.response?.status)
              // navigate(from, { replace: true });
              setLoginMsg(errorOrderDetail?.response?.data?.message);
              setShowLoginPopup(true);
            }else 
            setMsgState({open:true,msg:errorOrderDetail?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetOrderDetail();
        }
      },[loadedOrderDetail,responseDataOrderDetail]);

  //handle location change for accessing the status of payment
  useEffect(() => {
    console.log('Location changed======>',location);
    if(location.search!=undefined && location.search!=""){
      const searchParams = new URLSearchParams(location.search);
      const status=searchParams.get('status');
      if(status!=undefined && status!=""){
        if(status==PAYMENT_STATUS.SUCCESS){
          const order_id=searchParams.get('order_id');
          const  data={ "order_id" : order_id };
          executeAPIStatus(URL.SUBSCRIPTION_STATUS,"POST",data);

        }else{
          const message=searchParams.get('message');
          setMsgState({open:true,msg:message?message:TEXT_MSGS.NETWORK_ERROR_MSG,severity:"info"})
        }
      }
    }
  }, [location]);


  /**
   * API response handler subscription data
   */  
  useEffect(() => {
    if(loadedStatus){
      if(responseDataStatus!=null){
        verifyRefreshToken();
      }
      else if(errorStatus!==null){
        setMsgState({open:true,msg:errorStatus?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        //if unauthorized then redirec it to login page
        if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }
    }
    resetStatus();
    }
  },[loadedStatus,responseDataStatus]);

  const verifyRefreshToken = async () => {
    try {
        await refresh(true);
    }
    catch (err) {
        console.error(err);
    }
    finally {
        // do a reload here
        let reload_url=`${process.env.REACT_APP_URL}subscription`
        window.location=reload_url
        
    }
}

const handleTypeChange = (e) => {
    setType(e.target.value);
    if(e.target.value==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY){
        setTypeText(SUBSCRIPTION_MONTHLY_TYPE_TEXT.MONTHLY)
        setPeriod(1)
        let TotalPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.stocks_gst;
        let TotalIndexPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.base_gst;
        let TotalPremiumPlus=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.stocks_gst;
        let TotalPremiumPlusIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.base_gst;
        let TotalPremiumPlusOF=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks_gst;
        let TotalPremiumPlusOFIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base_gst;
        let TotalPremiumPlusOF_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks_gst_i;
        let TotalPremiumPlusOFIndex_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base_gst_i;
       
        setPremiumTotal(Math.round(monthlyDiscFactor*TotalPremium));
        setPremiumIndexTotal(Math.round(monthlyDiscFactor*TotalIndexPremium))
        setPremiumPlusTotal(Math.floor(monthlyDiscFactor*TotalPremiumPlus));
        setPremiumPlusIndexTotal(Math.floor(monthlyDiscFactor*TotalPremiumPlusIndex));
        setPremiumPlusOFTotal(Math.floor(monthlyDiscFactor*TotalPremiumPlusOF));
        setPremiumPlusOFIndexTotal(Math.floor(monthlyDiscFactor*TotalPremiumPlusOFIndex));
        setPremiumPlusOFTotal_i(Math.floor(monthlyDiscFactor*TotalPremiumPlusOF_i));
        setPremiumPlusOFIndexTotal_i(Math.floor(monthlyDiscFactor*TotalPremiumPlusOFIndex_i));

        setPremiumBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.stocks_gst)+" GST)");
        setPremiumIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].premium.base_gst)+" GST)");
        setPremiumPlusBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.stocks_gst)+" GST)");
        setPremiumPlusIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].expert.base_gst)+" GST)");
        setPremiumPlusOFBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.stocks_gst)+" GST)");
        setPremiumPlusOFIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.MONTHLY].elite.base_gst)+" GST)");
     
    }
    else if(e.target.value==SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY){
      setTypeText(SUBSCRIPTION_MONTHLY_TYPE_TEXT.QUARTERLY)
      setPeriod(3)

      let TotalPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.stocks_gst;
      let TotalIndexPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.base_gst;
      let TotalPremiumPlus=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.stocks_gst;
      let TotalPremiumPlusIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.base_gst;
      let TotalPremiumPlusOF=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks_gst;
      let TotalPremiumPlusOFIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base_gst;
      let TotalPremiumPlusOF_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks_gst_i;
      let TotalPremiumPlusOFIndex_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base_gst_i;
     
       
      setPremiumTotal(TotalPremium);
      setPremiumIndexTotal(TotalIndexPremium)
      setPremiumPlusTotal(TotalPremiumPlus);
      setPremiumPlusIndexTotal(TotalPremiumPlusIndex);
      setPremiumPlusOFTotal(TotalPremiumPlusOF);
      setPremiumPlusOFIndexTotal(TotalPremiumPlusOFIndex);
      setPremiumPlusOFTotal_i(TotalPremiumPlusOF_i);
      setPremiumPlusOFIndexTotal_i(TotalPremiumPlusOFIndex_i);

      setPremiumTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.stocks_actual);
      setPremiumIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.base_actual);
      setPremiumPlusTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.stocks_actual);
      setPremiumPlusIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.base_actual);
      setPremiumPlusOFTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks_actual);
      setPremiumPlusOFIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base_actual)


      setPremiumBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.stocks_gst)+" GST)");
      setPremiumIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].premium.base_gst)+" GST)");
      setPremiumPlusBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.stocks_gst)+" GST)");
      setPremiumPlusIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].expert.base_gst)+" GST)");
      setPremiumPlusOFBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.stocks_gst)+" GST)");
      setPremiumPlusOFIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.QUARTERLY].elite.base_gst)+" GST)");

  }
    else if(e.target.value==SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY){
        setTypeText(SUBSCRIPTION_MONTHLY_TYPE_TEXT.HALF_YEARLY)
        setPeriod(6)

      let TotalPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.stocks_gst;
      let TotalIndexPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.base_gst;
      let TotalPremiumPlus=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.stocks_gst;
      let TotalPremiumPlusIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.base_gst;
      let TotalPremiumPlusOF=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks_gst;
      let TotalPremiumPlusOFIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base_gst;
      let TotalPremiumPlusOF_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks_gst_i;
      let TotalPremiumPlusOFIndex_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base_gst_i;
     
       
      setPremiumTotal(TotalPremium);
      setPremiumIndexTotal(TotalIndexPremium)
      setPremiumPlusTotal(TotalPremiumPlus);
      setPremiumPlusIndexTotal(TotalPremiumPlusIndex);
      setPremiumPlusOFTotal(TotalPremiumPlusOF);
      setPremiumPlusOFIndexTotal(TotalPremiumPlusOFIndex);
      setPremiumPlusOFTotal_i(TotalPremiumPlusOF_i);
      setPremiumPlusOFIndexTotal_i(TotalPremiumPlusOFIndex_i);

      setPremiumTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.stocks_actual);
      setPremiumIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.base_actual);
      setPremiumPlusTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.stocks_actual);
      setPremiumPlusIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.base_actual);
      setPremiumPlusOFTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks_actual);
      setPremiumPlusOFIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base_actual)


      setPremiumBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.stocks_gst)+" GST)");
      setPremiumIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].premium.base_gst)+" GST)");
      setPremiumPlusBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.stocks_gst)+" GST)");
      setPremiumPlusIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].expert.base_gst)+" GST)");
      setPremiumPlusOFBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.stocks_gst)+" GST)");
      setPremiumPlusOFIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.HALF_YEARLY].elite.base_gst)+" GST)");

    }
    else if(e.target.value==SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY){
        setTypeText(SUBSCRIPTION_MONTHLY_TYPE_TEXT.ANNUALLY);
        setPeriod(12)

       let TotalPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.stocks_gst;
      let TotalIndexPremium=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.base_gst;
      let TotalPremiumPlus=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.stocks_gst;
      let TotalPremiumPlusIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.base_gst;
      let TotalPremiumPlusOF=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks_gst;
      let TotalPremiumPlusOFIndex=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base_gst;
      let TotalPremiumPlusOF_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks_gst_i;
      let TotalPremiumPlusOFIndex_i=SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base_i+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base_gst_i;
     
       
      setPremiumTotal(TotalPremium);
      setPremiumIndexTotal(TotalIndexPremium)
      setPremiumPlusTotal(TotalPremiumPlus);
      setPremiumPlusIndexTotal(TotalPremiumPlusIndex);
      setPremiumPlusOFTotal(TotalPremiumPlusOF);
      setPremiumPlusOFIndexTotal(TotalPremiumPlusOFIndex);
      setPremiumPlusOFTotal_i(TotalPremiumPlusOF_i);
      setPremiumPlusOFIndexTotal_i(TotalPremiumPlusOFIndex_i);

      setPremiumTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.stocks_actual);
      setPremiumIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.base_actual);
      setPremiumPlusTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.stocks_actual);
      setPremiumPlusIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.base_actual);
      setPremiumPlusOFTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks_actual);
      setPremiumPlusOFIndexTotalActual(SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base_actual)


      setPremiumBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.stocks_gst)+" GST)");
      setPremiumIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].premium.base_gst)+" GST)");
      setPremiumPlusBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.stocks_gst)+" GST)");
      setPremiumPlusIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].expert.base_gst)+" GST)");
      setPremiumPlusOFBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.stocks_gst)+" GST)");
      setPremiumPlusOFIndexBreakup("(= "+SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base+monthlyDiscTxt+" + "+Math.floor(monthlyDiscFactor*SUBSCRIPTION_PLANS_PRICE_NSE[SUBSCRIPTION_MONTHLY_TYPE.ANNUALLY].elite.base_gst)+" GST)");

    }
    
    
    // setStockVal(false);
    // setOrderflowVal(false);
    // setOrderflowStockVal(false);

    // console.log("----------- type", type, e.target.value)
    // if(stockVal) handleStockFnOChange(stockVal,e.target.value);
    // if(orderflowVal) handleOrdeflowChange(orderflowVal,e.target.value);
    // if(orderflowStockVal) handleOrdeflowStockChange(orderflowStockVal,e.target.value);
  };


  const continuePayment=(code)=>{
    // console.log("COUPON CODE=",code);
    setShowOrderPopup(false);
    executeAPIOrder(URL.SUBSCRIPTION_ORDER,"POST",{"plan_name": planName,"status_check":0,"disc_coupon":code})
}

    return (
      <>
      {isLoaded && isUserSubsLoaded?
      <Box sx={{ml:1}}>
        <Typography variant="h3" component="div" sx={{mb:1,mt:3,ml:2,textAlign:'center'}}>Subscription Plans</Typography>
        {/* <Typography variant="h4" sx={{mb:6,mt:4,ml:2,textAlign:'center',fontWeight:600,color:'#ffffff'}}>Happy 76th Republic Day! Get Additional 10% OFF on all Half-yearly and Annual plans.
          <br/>Use Coupon code <u style={{color:"#fa75e5"}}>REPUBLIC</u> on the Purchase Details popup.
          <br/>Offer valid only till 27th January. HURRY!</Typography> */}
        {/* <Typography variant="h4" sx={{mb:6,mt:4,ml:2,textAlign:'center',fontWeight:600,color:'#ffffff'}}>Happy New Year! Get FLAT 25% OFF on all Quarterly, Half-yearly and Annual plans.
          <br/>Use Coupon code <u style={{color:"#fa75e5"}}>HNY2025</u> on the Purchase Details popup.
          <br/>Offer valid only till 5th January. HURRY!</Typography> */}
        {/* <Typography variant="h4" sx={{mb:4,mt:5,textAlign:'center',fontWeight:600,color:'#acfb03'}}>NOTE: We have updated our plans and offerings to better align with our vision for future product enhancements.</Typography> */}
        {/* <Typography variant="h4" sx={{mb:4,mt:5,textAlign:'center',fontWeight:600,color:'#FBE103'}}>Special Diwali Offer - Save <u>MORE</u> when you subscribe to our Half-yearly and Annual plans.
        <br/>Higher Savings offer available only till <u>10th November</u>.
        <br/>HURRY and become a longer term member with the best possible prices Now!</Typography> */}
        {/* <Typography variant="h4" sx={{mb:4,mt:5,textAlign:'center',fontWeight:600,color:'#FBE103'}}>Special Independence Day Offer - Save <u>MORE</u> when you subscribe to our higher timeframe plans.
        <br/>Higher Savings offer available only till <u>18th Aug</u>.
        <br/>HURRY and become a longer term member with the best possible prices Now!</Typography> */}
        {/* <Typography variant="h4" sx={{mb:6,mt:4,textAlign:'center',fontWeight:600,color:'#FBE103'}}>Special Independence Day Offer - Get FLAT 20% OFF on all plans - Use Coupon code <u style={{color:"#F3B23E"}}>FREEDOM20</u> during checkout.
        <br/>Offer valid only till 20th Aug. HURRY!</Typography> */}
        {/* <Typography  sx={{mt:2,textAlign:'center',fontSize:"32px",fontWeight:600,color:'#F3B23E'}}>Stay tuned for our upcoming exciting new year discount offer on Annual Plans..</Typography> */}
        <br/>
        {/* <Box sx={{display:"flex",justifyContent:"center"}}><img src={bannerImg} alt="hny_offer" style={imgStyle} /><br/></Box> */}
        {/* <img src={miniBannerImg} alt="diwali_offer" style={{width: "100%", marginLeft: 0, marginRight: 40, marginTop: -20, marginBottom: 20}} /><br/> */}
       
        <Box sx={{ml:2,mt:1,display:"flex",justifyContent:"center",flexDirection:'column',alignItems:'center'}}>
        <Box sx={{display:'flex',ml:8}}>
        <Typography sx={{ml:13,fontSize:"18px",fontWeight:600,color:'#8ef97e'}}>Save 5 %</Typography>
        <Typography sx={{ml:8,fontSize:"18px",fontWeight:600,color:'#8ef97e'}}>Save 10 %</Typography>
        <Typography sx={{ml:8,fontSize:"18px",fontWeight:600,color:'#8ef97e'}}>Save 15 %</Typography>
        </Box>
        <Box sx={{display:'flex'}}>
            <Radio.Group  buttonStyle="solid"  onChange={handleTypeChange} value={type} size="large">
                <Radio.Button  style={{fontSize:"20px"}}value="monthly">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monthly&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Radio.Button>
                <Radio.Button style={{fontSize:"20px"}} value="quarterly">&nbsp;&nbsp;Quarterly&nbsp;&nbsp;</Radio.Button>
                <Radio.Button style={{fontSize:"20px"}} value="half_yearly">&nbsp;&nbsp;Half-yearly&nbsp;&nbsp;</Radio.Button>
                <Radio.Button style={{fontSize:"20px"}} value="annually">&nbsp;&nbsp;&nbsp;&nbsp;Annually&nbsp;&nbsp;&nbsp;&nbsp;</Radio.Button>
            </Radio.Group>
            </Box>
        </Box>
      
      {/* <Typography  sx={{mb:0,mt:2,textAlign:'center',fontSize:"26px",fontWeight:600,color:'#FBE103'}}>We celebrate India's Cricket World Cup victory by extending Diwali Offer by 1 additional day</Typography>
      <Typography  sx={{mb:1,textAlign:'center',fontSize:"26px",fontWeight:600,color:'#FBE103'}}>Top it up with an Additional 10% Discount across all Plans - Use Discount code: <u>INDIACRICKETCHAMP</u></Typography> */}
      {/* <Typography  sx={{mb:0,mt:2,textAlign:'center',fontSize:"28px",fontWeight:600,color:'#FBE103'}}>Chart your path to data-driven trading in 2024! <br/>Subscribe to our Annual Plans and enjoy an additional 30% discount with Coupon Code: <u>HNY2024</u>. <br/>Wishing you a happy new year and many more filled with financial achievements! <br/>Offer starts from 29th December and is valid till 7th January 2024.</Typography> */}
      {/* <Typography  sx={{mb:0,mt:2,textAlign:'center',fontSize:"28px",fontWeight:600,color:'#F3B23E'}}>Stay tuned for our upcoming exciting new year discount offer on Annual Plans..</Typography> */}
      {/* <Typography  sx={{mb:0,mt:2,textAlign:'center',fontSize:"25px",fontWeight:600,color:'#FBE103'}}>Save 5% with a Quarterly plan, 10% with a Half-yearly plan and Save 20% when you subscribe for an Annual plan</Typography> */}
      {/* <Typography  sx={{mb:1,textAlign:'center',fontSize:"24px",fontWeight:600,color:'#FBE103'}}>BONUS: Get Market Profile and Orderflow <Link href='https://vtrender.com/ecourse/' target='_blank' sx={{mb:1,textAlign:'center',fontSize:"24px",fontWeight:600,color:'#FBE103',cursor:"pointer"}} variant="link">ECourse</Link> Unlimited Access along with Annual Subscription of any Orderflow plan.. FREE!</Typography> */}

      <Typography  sx={{mb:2,mt:2,textAlign:'center',fontSize:"20px",fontWeight:600,color:'#FFFFFF',display:'flex',justifyContent:'center',alignItems:'center'}}>BONUS: 
        <Typography sx={{ml:1,fontSize:"20px",display:'flex',color:"#FFFFFF"}}>
        Get Market Profile and Orderflow ECourse - Unlimited Access - worth <RupeeIcon sx={{display:'flex',mt:0.5,textAlign:'center',fontSize:"20px",fontWeight:700,color:"#FFFFFF"}}></RupeeIcon> 5899, along with an &nbsp;<u>Annual Subscription</u>&nbsp; of any <Typography  sx={{ml:0.5,mr:0.5,textAlign:'center',fontSize:"20px",fontWeight:600,color:'#EC6C10',display:'flex',justifyContent:'center',alignItems:'center'}}>Elite</Typography> plan.. FREE!
        </Typography>
        </Typography>

      {/* <Typography  sx={{mb:0,mt:2,textAlign:'center',fontSize:"28px",fontWeight:600,color:'#FBE103'}}>Exciting Diwali Offers! <br/>Subscribe fast to take advantage of our limited-time discount on annual plans and lock-in the lowest price available!</Typography>
      <Typography  sx={{mb:1,mt:2,textAlign:'center',fontSize:"26px",fontWeight:600,color:'#FBE103'}}>Get a Flat 40% Discount on any Annual plan.</Typography>
      <Typography  sx={{mb:2,textAlign:'center',fontSize:"24px",fontWeight:600,color:'#FBE103'}}>Get Flat 10% Discount on any Half-yearly plan</Typography>
      <Typography  sx={{mb:1,textAlign:'center',fontSize:"24px",fontWeight:600,color:'#FBE103'}}>BONUS: Get MP and Orderflow <Link href='https://vtrender.com/ecourse/' target='_blank' sx={{mb:1,textAlign:'center',fontSize:"24px",fontWeight:600,color:'#FBE103',cursor:"pointer"}} variant="link">ECourse</Link> Unlimited Access along with Orderflow Annual Subscription FREE!</Typography> */}

        {!isError && !isErrorUserSubs?  
        <><Box sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
          
        
          <Box sx={{ml:4,mt:5,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
          <Box
          sx={{
            width: "90%",
            height: "5px",
            backgroundColor: "#FFFFFF",
            position: "relative",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Typography sx={{fontSize:"16px",fontWeight:700}}>Orderflow Plan</Typography> */}
       
        </Box>
        <Card  variant="outlined" sx={cardSX}>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',minHeight:"1680px"}}>
                <Typography  sx={{mb:2,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700}}>Free</Typography>
               
                <Typography sx={{mb:0,mt:1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#fff"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#fff"}}></RupeeIcon>0</Typography>
                {/* <Box display='flex' sx={{mt:20}} >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1,fontWeight:800}}>15 min Delayed feed</Typography>
                </Box> */}
                <Box display='flex' sx={{mt:8}} >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table basic details with only Nifty, BankNifty and FinNifty instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving ability for only continuous instruments</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto-Generated KRLs for NF/BNF, Custom and Anchored KRLs on MP Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Stocks FnO instrument data on MP charts</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Orderflow charts with our proprietary tools</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Unique short timeframe Candlestick and Line Charts for NF/BNF/FNF FnO instruments</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest, IV, Gamma and Market Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all options combined OI Change charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Useful Tooltips on Market Profile charts that also show some additional information for important references</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Market Profile Bookmarks</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Global Bookmarks for saving multi-window views</Typography>
                </Box>
              </Box>
              
            </Card>
        </Box>
          <Box sx={{ml:6,mt:5,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
          <Box
          sx={{
            width: "90%",
            height: "5px",
            backgroundColor: "#23B1BA",
            position: "relative",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Typography sx={{fontSize:"16px",fontWeight:700}}>Orderflow Plan</Typography> */}
       
        </Box>
        <Card  variant="outlined" sx={cardSXPlan}>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',height:"2080px"}}>
                <Typography sx={{mb:2,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#23B1BA"}}>Premium</Typography>
                {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                <Typography sx={{mb:0,mt:0,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#23B1BA"}}></RupeeIcon>{premiumTotal}</Typography>
                 :
                <Box sx={{display:'flex',mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#23B1BA"}}></RupeeIcon>{premiumTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#23B1BA",textDecoration:'line-through'}}>{premiumTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#23B1BA",background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumTotalActual}</Typography>
                  </Box>
                }
               
                  
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumBreakup}</Typography>
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#23B1BA"}}></RupeeIcon>{Math.round(premiumTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"100px",minHeight:"100px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>Stock Futures</Typography>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>Actively Traded Index Futures and Options</Typography>
                    </Box>
                    
                </Box>
                </Box>
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"100px",alignItems:'center',mb:4.5}}>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.PREMIUM,false)}  sx={buttonSX}>Buy Premium &#42;</Button>
                  <Typography sx={{mb:1,mt:0,textAlign:'center',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                 </Box>

                 {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                   <Typography sx={{mb:0,mt:4,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#23B1BA"}}></RupeeIcon>{premiumIndexTotal}</Typography>
                   :
                <Box sx={{display:'flex',mt:4,mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#23B1BA"}}></RupeeIcon>{premiumIndexTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#23B1BA",textDecoration:'line-through'}}>{premiumIndexTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#23B1BA",background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumIndexTotalActual}</Typography>
                  </Box>
                }
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumIndexBreakup}</Typography>
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#23B1BA"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#23B1BA"}}></RupeeIcon>{Math.round(premiumIndexTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"65px",minHeight:"65px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>Only Actively Traded Index Futures and Options</Typography>
                    </Box>
                </Box>
                </Box>
                {/* <Typography sx={{mb:0.1,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>ACTIVELY TRADED INDEX</Typography>
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>FUTURES AND OPTIONS ONLY</Typography> */}
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"100px",alignItems:'center',mb:8}}>
                  <Button size="large"  onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.PREMIUM,true)}   sx={buttonSX}>Buy Premium Index Plan &#42;</Button>
                  <Typography sx={{mb:1,mt:0,textAlign:'center',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                 </Box>
               
                  
                 <Box display='flex' sx={{mt:5}} >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:2,fontWeight:800}}>2 min Delayed feed</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty, BankNifty and FinNifty instruments</Typography>
                </Box>
                {/* <Box display='flex' >
                  <PlusIcon color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Near real-time live data across all charts</Typography>
                </Box> */}
                {/* <Box display='flex' >
                  <PlusIcon color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}><span style={{fontSize:"16px",fontWeight:700,color:"#e08a8a"}}>Add-On:</span> MP Charts for all Stock Futures and a few actively traded options for those stocks</Typography>
                </Box> */}
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Orderflow charts with our proprietary tools</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving supported across all instruments in MP charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto-Generated KRLs for NF/BNF and Custom KRLs on MP Charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Market Profile Bookmarks and Bookmark specific KRLs</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Unique short timeframe Candlestick and Line Charts for NF/BNF/FNF FnO instruments<br/>&nbsp;</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced IV Curve and Open Interest Visualization Charts<br/>&nbsp;</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Useful Tooltips on Market Profile charts that also show some additional information for important references</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all options combined OI Change charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts<br/>&nbsp;</Typography>
                </Box>
                <Box display='flex' >
                  <CloseIcon color ='#ccc' ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all Options Near To Money (NTM) weighed combined OI Change with Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Global Bookmarks for saving multi-window views</Typography>
                </Box>
               
                
              </Box>
            
            </Card>
           
        </Box>
        <Box sx={{ml:6,mt:5,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column",alignItems:'center'}}>
        <Box
          sx={{
            width: "90%",
            height: "5px",
            backgroundColor: "#E7B401",
            position: "relative",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Typography sx={{fontSize:"16px",fontWeight:700}}>Orderflow Plan</Typography> */}
       
        </Box>
        <Card  variant="outlined" sx={cardSXPlan}>
              <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',height:"2080px"}}>
                <Typography sx={{mb:2,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#E7B401"}}>Expert</Typography>
                {/* <Typography sx={{mb:0,mt:0,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#E7B401"}}></RupeeIcon>{premiumPlusTotal}</Typography> */}
                
                {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                 <Typography sx={{mb:0,mt:0,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#E7B401"}}></RupeeIcon>{premiumPlusTotal}</Typography>
                 :
                <Box sx={{display:'flex',mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#E7B401"}}></RupeeIcon>{premiumPlusTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#E7B401",textDecoration:'line-through'}}>{premiumPlusTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#E7B401",background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumPlusTotalActual}</Typography>
                  </Box>
                } 
               
                  
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumPlusBreakup}</Typography>
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#E7B401"}}></RupeeIcon>{Math.round(premiumPlusTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"100px",minHeight:"100px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All Liquid Futures and Options instruments of NSE</Typography>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>11,000+ Instruments</Typography>
                    {/* <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All liquid Futures and Options Instruments</Typography>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>8000+ instruments</Typography> */}
                    {/* <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>Index Futures and Options</Typography> */}
                    </Box>
                    
                </Box>
                </Box>
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"100px",alignItems:'center',mb:4.5}}>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.EXPERT,false)}   sx={buttonSXPlus}>Buy Expert &#42;</Button>
                  <Typography sx={{mb:1,mt:0,textAlign:'center',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                 </Box>

                 {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                <Typography sx={{mb:0,mt:4,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#E7B401"}}></RupeeIcon>{premiumPlusIndexTotal}</Typography>
                :
                <Box sx={{display:'flex',mt:4,mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#E7B401"}}></RupeeIcon>{premiumPlusIndexTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#E7B401",textDecoration:'line-through'}}>{premiumPlusIndexTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#E7B401",background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumPlusIndexTotalActual}</Typography>
                  </Box>
                } 
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumPlusIndexBreakup}</Typography>
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#E7B401"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#E7B401"}}></RupeeIcon>{Math.round(premiumPlusIndexTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"65px",minHeight:"65px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All Index specific Liquid Futures and Options Instruments</Typography>
                    </Box>
                </Box>
                </Box>
                {/* <Typography sx={{mb:0.1,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>ACTIVELY TRADED INDEX</Typography>
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>FUTURES AND OPTIONS ONLY</Typography> */}
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"100px",alignItems:'center',mb:8}}>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.EXPERT,true)}   sx={buttonSXPlus}>Buy Expert Index Plan &#42;</Button>
                  <Typography sx={{mb:1,mt:0,textAlign:'center',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                 </Box>
               
                  
                 <Box display='flex' sx={{mt:5}} >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:2,fontWeight:800}}>2 min Delayed feed</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty, BankNifty and FinNifty instruments</Typography>
                </Box>
                {/* <Box display='flex' >
                  <CheckIcon sx={{mb:1}} color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Near real-time live data across all charts</Typography>
                </Box> */}
                {/* <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts for all Stock Futures and a few actively traded options for those stocks</Typography>
                </Box> */}
                {/* <Box display='flex' >
                  <PlusIcon color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}><span style={{fontSize:"16px",fontWeight:700,color:"#e08a8a"}}>Add-On:</span> Orderflow Charts with our proprietary tools - for instruments based on selected Add-On</Typography>
                </Box> */}
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Orderflow charts with our proprietary tools</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving supported across all instruments in MP Charts</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto-Generated KRLs for NF/BNF and Custom & Anchored KRLs on MP Charts.</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Bookmarks and Bookmark specific KRLs.</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Unique short timeframe Candlestick and Line Charts for NF/BNF/FNF FnO instruments<br/>&nbsp;</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest, IV Curve, Gamma, Gamma Exposure and Market Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Useful Tooltips on Market Profile charts that also show some additional information for important references</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all options combined OI Change and Volumes charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts<br/>&nbsp;</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all Options Near To Money (NTM) weighed combined OI Change with Spectrum Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
                </Box>
                <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Global Bookmarks for saving multi-window views</Typography>
                </Box>
                {/* <Box display='flex' >
                <CloseIcon sx={{color:'#ccc'}} ></CloseIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Orderflow charts with our proprietary tools</Typography>
                </Box> */}
                
              </Box>
            
            </Card>
           
        </Box>
        <Box sx={{ml:6,mt:4,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
       
        <Box
          sx={{
            width: "90%",
            height: "20px",
            mb:-1,
            backgroundColor: "#EC6C10",
            position: "relative",
            borderRadius: "10px",
            display: "flex",
            zIndex: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{fontSize:"16px",fontWeight:700}}>Orderflow Plans</Typography>
       
        </Box>
        <Card  variant="outlined" sx={cardSXPlanOF}>
           
           <Box
              sx={{
                position: "relative",
                top: "22px",
                left: "-146px",
                transform: "rotate(-45deg)",
                overflow: "visible",
              }}
            >
              <Box
                sx={{
                  width: "120px",
                  height: "20px",
                  background:
                    "linear-gradient(218.09deg, #C7E7F9 6.56%, #3DBEE7 48.46%, #3DBEE7 90.36%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    transform: "rotate(45deg)",
                  }}
                >
                  <span
                    role="img"
                    aria-label="star"
                    style={{ marginRight: "4px" }}
                  >
                    <StarRateIcon sx={{ fontSize: "18px", marginTop: "5px" }} />
                  </span>{" "}
                </Typography>
              </Box>
            </Box>
              <Box sx={{display:'flex',marginTop:"-20px",flexDirection:"column",justifyContent:'flex-start',height:"2140px"}}>
             
                <Typography sx={{mb:2,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#EC6C10"}}>Elite</Typography>
                {/* <Typography sx={{mb:0,mt:0,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#EC6C10"}}></RupeeIcon>{premiumPlusOFTotal}</Typography> */}
                {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                 <Typography sx={{mb:0,mt:0,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#EC6C10"}}></RupeeIcon>{premiumPlusOFTotal}</Typography>
                 :
                <Box sx={{display:'flex',mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#EC6C10"}}></RupeeIcon>{premiumPlusOFTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#EC6C10", background:"linear-gradient(166deg, transparent 0%, transparent 46%, #EC6C10 50%, transparent 54%, transparent 100%)"}}>{premiumPlusOFTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#EC6C10", background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumPlusOFTotalActual}</Typography>
                  </Box>
                } 
               
                  
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumPlusOFBreakup}</Typography>
                <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#EC6C10"}}></RupeeIcon>{Math.round(premiumPlusOFTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"100px",minHeight:"100px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All Liquid Futures and Options instruments of NSE</Typography>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>11,000+ Live Instruments</Typography>
                    {/* <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All liquid Futures and Options Instruments</Typography>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>8000+ instruments</Typography> */}
                    {/* <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>Index Futures and Options</Typography> */}
                    </Box>
                    
                </Box>
                </Box>
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"144px",alignItems:'center',mb:1}}>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.ELITE,false)}  sx={buttonSXOF}>Buy Elite &#42;</Button>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.ELITE,false,true)}  sx={buttonSXOFInternational}>International Users Click Here&nbsp;<sup>&#35;</sup></Button>
                  <Typography sx={{mb:0,mt:0,textAlign:'left',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                  <Typography sx={{mb:1,mt:0,textAlign:'left',fontSize:"11px",fontWeight:500,color:"#fff"}}># Please Check NSE data access policy for International Users</Typography>
                 </Box>

                 {type==SUBSCRIPTION_MONTHLY_TYPE.MONTHLY?
                 <Typography sx={{mb:0,mt:2,ml:-1,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#EC6C10"}}></RupeeIcon>{premiumPlusOFIndexTotal}</Typography>
                 :
                 <Box sx={{display:'flex',mt:2,mb:0,ml:6,textAlign:'center'}}>
                    <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#EC6C10"}}></RupeeIcon>{premiumPlusOFIndexTotal}</Typography>
                    {/* <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#EC6C10",textDecoration:'line-through'}}>{premiumPlusOFIndexTotalActual}</Typography> */}
                    <Typography sx={{mb:0,ml:1,mt:2,textAlign:'center',fontSize:"25px",fontWeight:500,color:"#EC6C10",background:"linear-gradient(168deg, transparent 41%, currentColor 43%, currentColor 45%, transparent 47%)"}}>{premiumPlusOFIndexTotalActual}</Typography>
                  </Box>
                } 
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{premiumPlusOFIndexBreakup}</Typography>
                 <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"16px",fontWeight:700,color:"#EC6C10"}}><RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:600,color:"#EC6C10"}}></RupeeIcon>{Math.round(premiumPlusOFIndexTotal/period)} per month</Typography>
               
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>{typeText}</Typography>
                <Box sx={{display:'flex',mb:1,ml:1,mr:1,background:"#F4F2EA33",height:"65px",minHeight:"65px",borderRadius:"8px"}}>
                <Box sx={{display:'flex',mt:1,justifyContent:'space-between',width:"100%"}}>
                    <Box>
                    <Typography sx={{mb:1,marginTop:"0px",ml:1,fontSize:"16px",fontWeight:600,color:"#FFEEB4"}}>All Index specific Liquid Futures and Options Instruments</Typography>
                    </Box>
                </Box>
                </Box>
                {/* <Typography sx={{mb:0.1,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>ACTIVELY TRADED INDEX</Typography>
                <Typography sx={{mb:3,mt:0,textAlign:'center',fontSize:"18px",fontWeight:500,color:"#fff"}}>FUTURES AND OPTIONS ONLY</Typography> */}
                <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"144px",alignItems:'center',mb:2}}>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.ELITE,true)}   sx={buttonSXOF}>Buy Elite Index Plan &#42;</Button>
                  <Button size="large" onClick={()=>handleClick(SUBSCRIPTION_PLAN_TYPE.ELITE,true,true)}  sx={buttonSXOFInternational}>International Users Click Here&nbsp;<sup>&#35;</sup></Button>
                  <Typography sx={{mb:0,mt:0,textAlign:'left',fontSize:"11px",fontWeight:500,color:"#fff"}}>* Please Check our Refund and Cancellation policy below</Typography>
                  <Typography sx={{mb:1,mt:0,textAlign:'left',fontSize:"11px",fontWeight:500,color:"#fff"}}># Please Check NSE data access policy for International Users</Typography>
                </Box>
               
                  
                <Box display='flex' sx={{mt:0}} >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1,fontWeight:800,color:"#F7E437"}}>Live tick feed straight from NSE.</Typography>
                </Box>
                <Box display='flex' >
                  <StarIcon color ='success'></StarIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1,fontWeight:800,color:"#F7E437"}}>Real time precision feed off NSE servers. No broker or intermediary required.</Typography>
                </Box>
                <Box display='flex'>
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts, Options Table, Greeks Table and Index Table for Nifty, BankNifty and FinNifty instruments</Typography>
                </Box>
                {/* <Box display='flex' >
                  <CheckIcon sx={{mb:1}} color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Near real-time live data across all charts</Typography>
                </Box> */}
                {/* <Box display='flex' >
                  <CheckIcon color ='success'></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>MP Charts for all Stock Futures and a few actively traded options for those stocks</Typography>
                </Box> */}
                <Box display='flex' >
                  <PlusIcon color ='success'></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Orderflow Charts with our proprietary tools</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Historical MP Charts in different timeframes for higher timeframe market analysis</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto/Manual Saving supported across all instruments in MP Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Auto-Generated KRLs for NF/BNF and Custom & Anchored KRLs on MP Charts.</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Custom and Sharable Bookmarks and Bookmark specific KRLs.</Typography>
                </Box>
                <Box display='flex' >
                  <PlusIcon color ='success' ></PlusIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>New short timeframe Real time updated Candlestick charts for all liquid FnO instruments with our proprietary Orderflow signals</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Advanced Open Interest, IV Curve, Gamma, Gamma Exposure and Market Visualization Charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Useful Tooltips on Market Profile charts that also show some additional information for important references</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all options combined OI Change and Volumes charts</Typography>
                </Box>
                <Box display='flex' >
                  <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Compare current data snapshot with old data at selected date / time in Options Table and all OI and Inventory Visualization Charts to analyze how data changed</Typography>
                </Box>
               <Box display='flex' >
                 <CheckIcon color ='success' ></CheckIcon>
                 <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Options position payoff analysis feature in Greeks Table</Typography>
               </Box>
               <Box display='flex' >
                 <PlusIcon color ='success' ></PlusIcon>
                 <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Proprietary Options Spectrum Charts with Real time candlestick price updates</Typography>
               </Box>
               <Box display='flex' >
                 <CheckIcon color ='success' ></CheckIcon>
                 <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Series wise all Options Near To Money (NTM) weighed combined OI Change with Spectrum Charts</Typography>
               </Box>
               <Box display='flex' >
                 <CheckIcon color ='success' ></CheckIcon>
                 <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Multi-window chart view</Typography>
               </Box>
                <Box display='flex' >
                <CheckIcon color ='success' ></CheckIcon>
                  <Typography sx={{fontSize:"14px",ml:1,mt:0,mb:1}}>Global Bookmarks for saving multi-window views</Typography>
               </Box>
              
               {/* <Typography sx={{mb:0,mt:5,textAlign:'left',fontSize:"12px",fontWeight:500,fontStyle:"italic",color:"#fff"}}><sup>1</sup> There could be a lag of 2-3 seconds due to data processing overhead</Typography> */}
             </Box>
           
           </Card>
           
        </Box>
      
     
      </Box>
      <Box sx={{ml:3,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:"column"}}>
      <Card  variant="outlined" sx={cardEcourseSX}>
        <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-start',minHeight:"180px"}}>

        <Typography sx={{mb:-2,mt:2,textAlign:'center',fontSize:"22px",fontWeight:700,color:"#FBE103"}}>MarketProfile and Orderflow Trading Strategies</Typography>
        <Typography sx={{mb:0,mt:2,textAlign:'center',fontSize:"30px",fontWeight:700,color:"#FBE103"}}><Link href='https://vtrender.com/ecourse/' target='_blank' sx={{mb:0,textAlign:'center',fontSize:"30px",fontWeight:700,color:'#FBE103',cursor:"pointer"}} variant="link">ECourse</Link> Subscription</Typography>
            <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"40px",fontWeight:700,color:"#FBE103"}}><RupeeIcon sx={{mb:-0.5,mt:0,textAlign:'center',fontSize:"35px",fontWeight:700,color:"#FBE103"}}></RupeeIcon>5899</Typography>
            <Typography sx={{mb:0.5,mt:-1,textAlign:'center',fontSize:"16px",fontWeight:600,color:"#FBE103"}}>Special Offer - Get FREE<sup>#</sup> 8 day access to Elite Index only plan which gives you Orderflow charts for Index instruments and Complete Options Suite access<br/>worth <RupeeIcon sx={{mb:-0.2,mt:0,textAlign:'center',fontSize:"14px",fontWeight:700,color:"#FBE103"}}></RupeeIcon>1000 + GST</Typography>
            <Typography sx={{mb:0,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>(= 4999 + 900 GST)</Typography>
            <Typography sx={{mb:2,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>one-time charges</Typography>
            <Box sx={{display:'flex',flexDirection:"column",justifyContent:'flex-end',height:"120px",alignItems:'center',mb:1}}>
              <Typography sx={{mb:2,mt:1,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>Note: GST at 18% rate applicable on plan cost.</Typography>
              <Button size="medium"  disabled={ecourseAlreadyBought} onClick={handleClickEcourse}  sx={buttonSXPlusMedium}><Typography sx={{color:'#51524F',fontWeight:600}}>{ecourseAlreadyBought?"Bought":"Buy Now*"}</Typography></Button>
              <Typography sx={{mb:0,mt:1,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}>* Please Check the ECourse Terms & Conditions below</Typography>
              <Typography sx={{mb:1,mt:0,textAlign:'center',fontSize:"12px",fontWeight:500,color:"#fff"}}><sup>#</sup> Non upgradable trial plan</Typography>
            </Box>
      </Box>
      </Card>
      </Box></>:
      <></>}
  <br/>
        {userSubsData!=undefined && userSubsData.active_subscriptions.length>0?
            <Box sx={{mt:3,display:'flex',justifyContent:"center"}}>
              
                <Box sx={{display:'flex',mb:1,pb:1,background:"#fff",borderRadius:"8px",pl:2,pr:2,mt:1,flexDirection:'column'}}>
              {userSubsData.active_subscriptions.map((item,index) => (
                
                 <>
                    {index==0?
                    <Typography  sx={{mb:1,mt:1,textAlign:'center',color:"#1e3a8a",fontSize:"20px",fontWeight:600}}>Active Subscription(s)</Typography>:
                    <></>}
                    <Box sx={{display:'flex',mb:1,pb:0,background:"#bfd2f2",borderRadius:"8px",pl:2,pr:2,mt:0,pt:1,flexDirection:'column'}}>
                        <Box sx={{display:'flex'}}>
                            <Typography  sx={{mb:1,textAlign:'start',color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Plan Details:</Typography>
                            <Typography  sx={{mb:1,ml:1,textAlign:'start',color:"#0d203f"}}>{item.description}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography  sx={{mb:2,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Expiry:</Typography>
                            <Typography  sx={{mb:2,ml:1,color:"#0d203f"}}>{item.expiry_date}&nbsp;(in&nbsp;{item.days_to_expiry}&nbsp;days)</Typography>
                        </Box>
                    </Box>
                    {index==userSubsData.active_subscriptions.length-1?
                    <Typography  sx={{mb:1,ml:2,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>
                      If you just now subcribed and are unable to access resources as per your assigned plan, please relogin once and check again.
                      <br/><Typography variant='subtitle2' sx={{mb:0,mt:0,textAlign:'center'}}>Feel free to reach out to us at <Link href="mailto:charts@vtrender.com" sx={{color:"blue"}}>charts@vtrender.com</Link> in case of any issues or queries.</Typography>
                    </Typography>:
                    <></>}
                                </>  
                          ))}
                           </Box>
                 
            </Box>:
            <>
            </>
          }
           {userSubsData!=undefined && userSubsData.halted_subscriptions.length>0?
            <Box sx={{mt:3,display:'flex',justifyContent:"center"}}>
              
                <Box sx={{display:'flex',mb:1,pb:1,background:"#fff",borderRadius:"8px",pl:2,pr:2,mt:1,flexDirection:'column'}}>
              {userSubsData.halted_subscriptions.map((item,index) => (
                
                 <>
                    {index==0?
                    <Typography  sx={{mb:1,mt:1,textAlign:'center',color:"#1e3a8a",fontSize:"20px",fontWeight:600}}>Halted Subscription(s)</Typography>:
                    <></>}
                    <Box sx={{display:'flex',mb:1,pb:0,background:"#bfd2f2",borderRadius:"8px",pl:2,pr:2,mt:0,pt:1,flexDirection:'column'}}>
                        <Box sx={{display:'flex'}}>
                            <Typography  sx={{mb:1,textAlign:'start',color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Plan Details:</Typography>
                            <Typography  sx={{mb:1,ml:1,textAlign:'start',color:"#0d203f"}}>{item.description}</Typography>
                        </Box>
                        <Box sx={{display:'flex'}}>
                            <Typography  sx={{mb:2,color:"#0d203f",fontWeight:600,minWidth:"140px"}}>Halted On:</Typography>
                            <Typography  sx={{mb:2,ml:1,color:"#0d203f"}}>{item.subscription_halted_on}&nbsp;(Remaining validity: &nbsp;{item.subscription_remaining_days}&nbsp;days)</Typography>
                        </Box>
                    </Box>
                    <Typography  sx={{mb:1,color:"#0d203f",fontWeight:600,minWidth:"140px",textAlign:'center'}}>
                      Halted plans will resume automatically as soon as your active plans expire.
                      <br/><Typography variant='subtitle2' sx={{mb:0,mt:0,textAlign:'center'}}>Feel free to reach out to us at <Link href="mailto:charts@vtrender.com" sx={{color:"blue"}}>charts@vtrender.com</Link> in case of any issues or queries.</Typography>
                    </Typography>
                   
                                </>  
                          ))}
                           </Box>
                 
            </Box>:
            <>
            </>
          }  

        {userSubsData!=undefined && userSubsData.pending_subscriptions.length>0?
            <>
                <Typography variant='h6' sx={{mb:1,mt:2,textAlign:'center'}}>List of Pending Plans that you successfully purchased and will be activated once the current plan expires:</Typography>
                    
                <Box sx={{mt:1,display:'flex',justifyContent:"center"}}>
                    <Box sx={{display:'flex',mb:1,pb:1,background:"#fff",borderRadius:"8px",pl:2,pr:2,mt:1,pt:1,flexDirection:'column'}}>
                    {userSubsData.pending_subscriptions.map((item,index) => (
                            <Typography variant='subtitle1' sx={{textAlign:'left',color:"#1e3a8a",fontWeight:600}}>&nbsp;&nbsp;+ {item.description} &nbsp;</Typography>
                    ))}
                    </Box>
                </Box>
            </>
            :
            <>
            </>
          }

        <br/>
        <br/>
        <Typography sx={{ml:0,mb:-1,fontSize:"20px",display:'flex',color:"#FBE103"}}>Important points to note when subscribing to a new plan or upgrading from an existing plan:</Typography>
        
        {/* <Box sx={{mt:0,display:'flex',justifyContent:"center"}}> */}
          {/* <Typography  sx={{mb:1,mt:0,ml:5,textAlign:'left',fontSize:"15px",minWidth:"980px",maxWidth:"980px"}}> */}
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  You can either upgrade your plan with higher privileges or safely top-up your existing plan from any of the options above. 
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  In case of an upgrade, your old plan remaining benefits will be converted on pro-rata basis into the newly selected plan immediately and corresponding additional days will be added to your new plan as soon as you upgrade.
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  In case of a top-up of the existing plan, your active plan pending days for Expiry should get updated immediately.
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  Any complementary plan that is bundled with ECourse is only for trial purposes. It does not come with a pro-rata upgrade ability.
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  In case you opt for a downgrade when purchasing a new plan, we will activate the new plan automatically only after your current plan with higher privileges expires.
          <br/>
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  All paid subscription plans operate under the assumption of fair usage, wherein accounts are designated for individual use and should not be shared with any third parties or friends for any purpose. 
          <br/><ArrowIcon fontSize='small' sx={{mb:-0.5}}></ArrowIcon>  Any unauthorized simultaneous access to charts on different devices without prior written consent of Vtrender Charts may incur penalties and you might get logged out of the website untimely and randomly, with possibility of account getting blocked permanently too.
        {/* </Typography> */}
        {/* </Box> */}
       
        <br/>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          Note: Prices are subject to change, and our plans may become more expensive in the future as we further enhance our services and develop more feature rich, unique and proprietary chart visualizations for you.
          <br/>Subscribe now to secure the best possible rate for our services.
        </Typography>

          <br/><br/>
          <hr/>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          <Typography variant='h6'>*ECourse Terms & Conditions</Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0 }}>
          For more details on this MarketProfile and Orderflow ECourse check the link: <Link href='https://vtrender.com/ecourse/' target='_blank' sx={{mb:1,textAlign:'center',fontSize:"17px",fontWeight:600,color:'#F3B23E',cursor:"pointer"}} variant="link">here</Link>
          </Typography>
          <br/><b style={{color:"#F3B23E"}}>ECourse Activation:</b> The activation of ECourse is a manual step and though we try to do it ASAP, but please allow for a time of around 12-24 hours for the subscription to be activated.
          <br/><b style={{color:"#F3B23E"}}>One-Time Access:</b> The purchase grants the user a one-time access to the ECourse on Financial Markets & Trading Strategies.
          <br/><b style={{color:"#F3B23E"}}>No Recurring Fees:</b> The mentioned fee for the ECourse is a one-time charge. There will be no recurring charges or subscription fees after the initial payment.
          <br/><b style={{color:"#F3B23E"}}>ECourse Content:</b> The current plan offers access to 12 Expert-Led Online Video Tutorials. Any additional content, including but not limited to supplementary materials, handouts, or quizzes, is not guaranteed unless explicitly mentioned.
          <br/><b style={{color:"#F3B23E"}}>Future Courses Exclusion:</b> This purchase does not include access to any future courses, updates, or additions that may be made. Future courses or updates will need to be purchased separately.
          <br/><b style={{color:"#F3B23E"}}>No Refunds:</b> Once the purchase is made, no refunds are possible under any circumstances. Ensure you have read all the course details and terms before making the purchase.
          <br/><b style={{color:"#F3B23E"}}>Usage Limitation:</b> The purchased ECourse is for the individual use of the purchaser only. Sharing, distributing, or reproducing the content in any form is strictly prohibited and will result in immediate termination of access without any refunds.
          <br/><b style={{color:"#F3B23E"}}>Expert Guidance:</b> While our experts have vast experience and provide valuable insights, it's important to understand that trading and investment decisions should be made based on one's own judgment. We do not guarantee any specific financial outcomes or results from following our guidance.
          <br/><b style={{color:"#F3B23E"}}>Continuous Learning:</b> The derivatives markets and trading strategies evolve over time. It's important to keep updating your knowledge and not solely rely on the current content of this ECourse for long-term trading decisions.
          <br/><b style={{color:"#F3B23E"}}>Rate Changes:</b> The price for the ECourse, as mentioned, is subject to change without any prior notice. The rate at the time of purchase is final.
          <br/><b style={{color:"#F3B23E"}}>Complementary Benefits:</b> The Complementary 8-day Elite plan bundled with ECourse enables you to try out all chart features and it also gives you limited Orderflow charts access for Index Instruments only.
        </Typography>
        <br/>
        <hr/>
        <Typography id="modal-modal-description" sx={{ mt: 4, ml: 0 }} variant="h6">
          In case you are not able to use the above payment options, 
          please use the following QR code for making payments via any UPI application: <br/>
          <img src={upiImg} alt="vtrendercharts@icici" width={200} height={200} style={{marginLeft:60, marginTop:10}} /><br/>
          UPI ID: <u>vtrendercharts@icici</u><br/>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, ml: 0, fontSize: 20 }}>
          &nbsp;&nbsp; - Please send a screenshot of the payment success page which has the Transaction Reference Number, your login Email ID and the details of subcription plan applied for to <Link href="mailto:charts@vtrender.com">charts@vtrender.com</Link> so that we can process your payment.<br/>
          &nbsp;&nbsp; - Please allow for a time of around 12-24 hours for the subscription to be activated.
        </Typography>
        <br/>
        <hr/>
        <Typography id="modal-modal-description" sx={{ mt: 8, fontWeight: 600 }} variant="h5">
          Disclaimer
          <br />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Although information has been obtained from and is based upon sources we believe to be reliable, we do not guarantee its accuracy and the information may be incomplete or condensed. This Website is for informational and analytical purposes only and none of the stock information, data and company information presented herein constitutes a legally binding recommendation or a solicitation of any offer to buy or sell any securities. Information presented is general information that does not take into account your individual circumstances, financial situation, or needs, nor does it present a personalized recommendation to you. Individual stocks presented may not be suitable for you.
        <br />
        <br />
        Any opinions, chats, messages, news, research, analyses, prices, or other information contained on this Website are provided as general market information for educational and entertainment purposes only, and do not constitute investment advice. The Website should not be relied upon as a substitute for extensive independent market research before making your actual trading decisions. Opinions, market data, recommendations or any other content is subject to change at any time without notice. Vtrender Charts will not accept liability for any loss or damage, including without limitation any loss of profit, which may arise directly or indirectly from use of or reliance on such information. Vtrender Charts is a research and information company and not investment/trading adviser. Please consult an adviser about the appropriateness of your investment/trading decisions.
        <br />
        <br />
        Note:<br />
        We are not SEBI Registered Advisor and We are NOT responsible for your trading profits/losses if you use our charts and data for trading. Please consult your investment advisor.
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 8, fontWeight: 600 }} variant="h5">
          Cancellations and Refunds for Products
          <br />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          We are committed to providing you with the highest quality service possible. However, please be advised that our charting service is a digital product and as such, we do not offer refunds once the service has been purchased and access has been granted.
          <br />
          <br />
          In order to ensure your satisfaction with our charting service, we recommend that you carefully review all product information, including the product description, before making a purchase. If you have any questions or concerns about the product, please feel free to contact us prior to making a purchase.
          <br />
          <br />
          By purchasing our charting service, you acknowledge and agree that you understand that there will be no refunds for any reason, including but not limited to:
          <br />
          &nbsp;&nbsp;- Dissatisfaction with the product or its performance
          <br />
          &nbsp;&nbsp;- Technical difficulties or issues with accessing the service
          <br />
          &nbsp;&nbsp;- Change of mind or circumstances
          <br />
          &nbsp;&nbsp;- Inability to use the product due to technical requirements or compatibility issues
          <br />
          <br />
          If you have any technical issues with accessing the charting service, please contact us immediately so that we can assist you in resolving the issue. We provide you with the best possible experience and will work to resolve any technical difficulties in a timely manner.
          <br />
          <br />
          Please note that our no refund policy is a fundamental part of our charting service and is non-negotiable. By purchasing and accessing the charting service, you agree to be bound by this policy.
          <br />
          <br />
          If you have any questions or concerns about our no refund policy, please feel free to contact us.

        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 4, fontWeight: 700 }} variant="h5">
          No Refund Policy
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Vtrender Charts provides a comprehensive charting service product and ensures the highest quality of service to our clients.
          However, please note that due to the nature of our services, we have a strict no refund policy.
          All sales are final, and no refunds will be issued for any reason, including but not limited to dissatisfaction with the product, changes in personal circumstances, or technical difficulties.
          <br /><br />
          By purchasing our charting service product, you acknowledge and agree to our no refund policy and that your purchase is a one-time transaction and non-refundable.
          Vtrender Charts is not responsible for any inconvenience or loss that may result from using our services.
          <br /><br />
          If you have any questions or concerns about our charting service product or no refund policy, please contact us before making a purchase.
          Our customer service team will be happy to assist you and answer any questions you may have.
          <br /><br />
        </Typography>

        <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
       {showorderPopup?
        <OrderDetailPopup data={orderDetailsData} planName={planName} onProceed={continuePayment} onCancel={()=>setShowOrderPopup(false)} isInternational={isInternational} amountDifference={amountDifference}></OrderDetailPopup>
        :
        <></>
       }
         {showLoginPopup?
          <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}

      </Box> :
        <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
        </div>} 
      </>          
       
    )
}

export default Subscription
