import React from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'; // Horizontal Line Icon
import ShowChartIcon from '@mui/icons-material/ShowChart'; // Angular Line Icon
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'; // Parallel Lines Icon
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import { DRAWING_OBJECT_TYPE } from "../../../common/utility/constant";



const RayIcon = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{ position: "relative", width: "fit-content" }}
      >
        {/* Circle at the start */}
        <Box
          sx={{
            width: "10px", // Diameter of the circle
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "transparent", // Make the circle hollow
            border: "2px solid black", // Border color and thickness
            marginRight: "-10px", // Space between the circle and the line
          }}
        />
        {/* Horizontal line */}
        <HorizontalRuleIcon sx={{width:24,height:24,marginLeft:"4px"}} />
      </Box>
    );
  };
const tools = [
    { id: DRAWING_OBJECT_TYPE.HORIZONTAL_RAY, label: 'Horizontal Ray', icon: <RayIcon /> },
    { id: DRAWING_OBJECT_TYPE.HORIZONTAL_LINE, label: 'Horizontal Line', icon: <HorizontalRuleIcon /> },
    { id: DRAWING_OBJECT_TYPE.LINE, label: 'Line', icon: <HorizontalRuleIcon sx={{ transform: "rotate(-45deg)"}} /> },
    { id: DRAWING_OBJECT_TYPE.PARALLEL_CHANNEL, label: 'Parallel Channel', icon: <DensityLargeIcon  sx={{ transform: "rotate(-45deg)"}}/> },
    { id: DRAWING_OBJECT_TYPE.HORIZONTAL_CHANNEL, label: 'Horizontal Channel', icon: <DensityLargeIcon /> },
  ];

const ToolMenu = ({ isOpen, selectedTool, onSelectTool,top=32,left=-100 }) => {
  if (!isOpen) return null; // Don't render if menu is closed

  return (
    <Box
      sx={{
        position: "absolute",
        top: top, // Adjust spacing as needed
        left: left,
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "8px",
        zIndex: 9999, // Ensure it's above other elements
        width: 240,
        height: 44,
        overflow: "visible",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {tools.map((tool) => (
        <Tooltip key={tool.id} title={tool.label}>
          <IconButton
            onClick={() => onSelectTool(tool.id)}
            sx={{
              height: 30,
              borderRadius: 2,
              color: selectedTool === tool.id ? "blue" : "black",
              backgroundColor: selectedTool === tool.id ? "#e0f7fa" : "#E5E4E2",
              "&:hover": {
                backgroundColor: selectedTool === tool.id ? "#b2ebf2" : "#F7F7F4",
              },
            }}
          >
            {tool.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

export default ToolMenu;
