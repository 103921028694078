import {styled} from '@mui/material/styles';
import { Toolbar} from '@mui/material'

/**
 * Toolbar common component for fixed header at the top and at the bottom
 */
export const ToolbarFixed=styled(Toolbar)(
  ({theme})=> `
  background-color:${theme.palette.primaryTheme.shade01};
  width:100vw;
  height:32px;
  position:fixed;
  z-index:999999;
  overflow: visible; // Allow overflow for children
 
  ${theme.breakpoints.up('mobile')}{
    min-height:32px;
  }
 `
)
// border-bottom: 1px solid ${theme.palette.primaryTheme.shade03};