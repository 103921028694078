import React, {useState,useEffect,useRef,useContext} from 'react'

import MPChartRightPanel from './components/mpchart_right_panel';
import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION, GLOBAL_BOOKMARK_RESULTS } from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { getChartSavedState, useQuery } from '../../common/utility/helperFunc';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { VA_TYPE,VP_TYPE,CHART_SCREEN_TYPE,CHART_TYPE,RIGHT_PANEL_CONTAINER_WIDTH } from '../../common/utility/mp_chart_constants';
import ChartContainer from './chart_container';
import { useWindowSize } from '../../common/hooks/useWindowSize';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS } from '../../common/utility/constant';
import './styles/styles.scss';
import SubscriptionPopup from './components/subscription_popup';
import MPChartRightPanelContainer from './components/mp_chart_right_container';
import LoginModal from './components/login_popups';
import SaveGlobalBookmarkDialog from './components/create_global_bookmark_dialog';
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth'
import { v4 as uuidv4 } from "uuid";
import { URL } from '../../common/api/urls';
import {useSearchParams} from 'react-router-dom';


const MarketProfileCharts = (props) => {
  
  // console.log("props==========================>",props);
  const windowSize=useWindowSize();
  const { auth } = useAuth();
  const bookmarkID = useQuery("bookmarkID");
  const [chartScreenType,setChartScreenType]=useState(CHART_SCREEN_TYPE.FULL_CHART)
  const [isBasicUser,setIsBasicUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?false:true)
  const [isProUser,setIsProUser]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))?true:false)
  const [isPro2User,setIsPro2User]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?true:false)
  const [showPopup,setShowPopup]=useState(false)
  const [showProPopup,setShowProPopup]=useState(false)
  const {userSettings, setUserSettings } = useContext(UserSettingsContext);
  const [rightConatianerState, setrRghtConatianerState] = useState({
    rightConatianerVisible:false,
    metadata:undefined,
    histoticalData:undefined,
    selectedIndexes:[],
    isLiveSession:false,
    showLive:false,
  })
  const [parentChartType,setParentChartType]=useState(CHART_TYPE.MARKET_PROFILE);
  const {rightConatianerVisible,metadata,histoticalData,selectedIndexes,isLiveSession,showLive}=rightConatianerState;
  const [toggleLiveButton,setToggleLiveButton]=useState(false);
  const [subscriptionPopupData,setSubscriptionPopupData]=useState({isCustom:false,msg:""});
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);

  // console.log("locale set: ", userSettings.data.number_format_locale)
  const [savedData, setSavedData] = useState({
    type:chartScreenType,  
    data:[
    {key:0},
    {key:1},
    {key:2},
    {key:3},
    {key:4},
    {key:5}
  ]});
  const [savedStateLoaded,setSavedStateLoaded]=useState(false);

  const [ChartContainerState,setChartContainerState]=useState({
    loaded:false,
    chartStateData:[
    {
      top:0,
      left:0,
      height:0,
      width:0,
      bookmarkID:bookmarkID,
      type:CHART_TYPE.MARKET_PROFILE,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.MARKET_PROFILE,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.MARKET_PROFILE,
      showPriceTable:false
    },
    {
      top:0,
      left:0,
      height:0,
      width:0,
      type:CHART_TYPE.MARKET_PROFILE,
      showPriceTable:false
    },{
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      type: CHART_TYPE.MARKET_PROFILE,
      showPriceTable: false,
    },
    {
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      type: CHART_TYPE.MARKET_PROFILE,
      showPriceTable: false,
    },
  ]
  })  
  
  const {chartStateData,loaded}=ChartContainerState;

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });

  const { open, msg, severity } = msgState;
  
  const [isLiveEnabled,setIsLiveEnabled]=useState(false);

  const globalBookmarkID = useQuery('globalBookmarkID')
  const [isGlobalBookmark,setIsGlobalBookmark]=useState(false);
  const [globalBookmarkData,setGlobalBookmarkData]=useState();
  const [openBookmarkDialog,setOpenBookmarkDialog]=useState(false);
  const [cancel,responseData, error, loadedSave, reset,executeAPI]  = useAxiosWithAuth();
  const [cancelBookmark,responseDataBookmark, errorBookmark, loadedBookmark, resetBookmark,executeAPIBookmark]  = useAxiosWithAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const from = "/login";
  const navigate = useNavigate(); 
  const [tempUUID,setTempUUID]=useState();
  const [isDirty,setIsDirty]=useState(false);
  const { setHeaderData } = useContext(UserSettingsContext);

  const saveGlobalBookmark=()=>{
    console.log("GlobalBk Bookmark save is called from right panel");
    if(isGlobalBookmark){
      if(isDirty)
      saveExistingBookamrk()
    }else{
      setOpenBookmarkDialog(true);
    }
   
  }
  
  const setChartLoaded=(val)=>{
    // console.log("HelloTest val 2=",val)
    setIsLiveEnabled(val);
  }

  useEffect(() => {
    document.title = "MP Charts";
    var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
    document.head.appendChild(link);
    var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', 'Market Profile charts. Futures and Options - individual, straddle, strangle. NIFTY. BANKNIFTY. FINNIFTY. MIDCPNIFTY.');
    document.head.appendChild(meta);
  }, []);

  useEffect(() => {
    // Function that runs before the tab reloads or closes
    const handleBeforeUnload = (e) => {
      console.log("handleBeforeUnload")
      const location = window.location;
  
    // Get current URL + query params (e.g., "/url?id=1")
    const currentUrlWithParams = `${location.pathname}${location.search}`;

       let tempData={type:savedData.type,
        [currentUrlWithParams]:savedData.data
        }
        if(!isGlobalBookmark)
        sessionStorage.setItem('savedMPData', JSON.stringify(tempData));
       // Custom message in some browsers (ignored in modern browsers)
     
       if(isDirty && isGlobalBookmark){
        const message = "Are you sure you want to leave?";
        e.preventDefault();
        e.returnValue = message;  // This is necessary for some browsers to show the dialog
        return message;  // This will trigger the browser dialog
        }
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [savedData,isDirty]);

  useEffect(()=>{
    if (!globalBookmarkID) {
      const location = window.location;
    const currentUrlWithParams = `${location.pathname}${location.search}`;
    let chartData = sessionStorage.getItem('savedMPData');
    if(chartData){
        chartData=JSON.parse(chartData);
        const chartDataForUrl = chartData[currentUrlWithParams];
        console.log("Reload chart data from sessionStorage=", chartData,chartDataForUrl);
        if(chartDataForUrl){
          setChartScreenType(chartData?.type)
          setSavedData({type:chartData?.type,data:chartDataForUrl});
          handleChartScreenType(chartData?.type)
        }
        sessionStorage.removeItem("savedMPData")
      }
    setSavedStateLoaded(true);
  } else {
    executeAPIBookmark(`${URL.GET_GLOBAL_BOOKMARK}?global_id=${globalBookmarkID}`, "GET", {})
  }
   
},[])

//get global bookmark api response handler
useEffect(() => {
  if (loadedBookmark) {
    console.log("Bookmark loaded ========>", responseDataBookmark);
    if (responseDataBookmark != null) {
      if (responseDataBookmark.data) {
        console.log("BookmarkData loaded objec=", { type: responseDataBookmark.data.type, data: responseDataBookmark.data })
        setChartScreenType(responseDataBookmark.data.type)
        setSavedData({ type: responseDataBookmark.data.type, data: responseDataBookmark.data.data });
        handleChartScreenType(responseDataBookmark.data.type)
        setIsGlobalBookmark(true);
        setGlobalBookmarkData(responseDataBookmark) //{data, type}
        setSavedStateLoaded(true);
        setTempUUID(globalBookmarkID);
        setHeaderData({name:responseDataBookmark.name,id:globalBookmarkID});

      } else {
        console.log("BookmarkData loaded null data=");
        if(responseDataBookmark.result==GLOBAL_BOOKMARK_RESULTS.NOT_FOUND)
          setMsgState({ open: true, msg: TEXT_MSGS.BOOKMARK_NOT_FOUND, severity: "info" });
        else 
          setMsgState({ open: true, msg: TEXT_MSGS.BOOKMARK_ACTION_NOT_ALLOWED, severity: "info" });
        handleChartScreenType(CHART_SCREEN_TYPE.FULL_CHART)
        setSavedStateLoaded(true);
        setTempUUID(undefined)

      }
    } else if (errorBookmark !== null) {
      console.log("Error data=", errorBookmark);
      if (errorBookmark?.response?.status === 404) {
        setSearchParams({});
        setSavedStateLoaded(true);
        setMsgState({ open: true, msg: errorBookmark?.response?.data?.message ?? `${TEXT_MSGS.BOOKMARK_NOT_FOUND}`, severity: "info" });
        setTempUUID(undefined)
      }

      else if (errorBookmark?.response?.status === 401 || errorBookmark?.response?.status === 403) {
        console.log("status received =", errorBookmark?.response?.status)
        navigate(from, { replace: true });
      } else {
        setMsgState({ open: true, msg: errorBookmark?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`, severity: "info" });
      }
    }
    resetBookmark();

  }

}, [loadedBookmark, responseDataBookmark])


  /**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};


const handleChartScreenType=(val,force=false)=>{
  console.log("chart screen type changed val=",val);
  if(force)
    saveChartState(undefined,val);
  setChartContainerState((prevState) => ({
    ...prevState,
    loaded:false,
    }));
   setChartScreenType(val); 
   const rightMargin=(rightConatianerVisible?(RIGHT_PANEL_CONTAINER_WIDTH+51):51)
   if(val==CHART_SCREEN_TYPE.FULL_CHART){
    let leftData=chartStateData[0];
    let width=Math.floor((window.innerWidth-rightMargin));
    let height=Math.floor((window.innerHeight - 48));
    leftData.top=0;
    leftData.left=0;
    leftData.height=height;
    leftData.width=width;
    chartStateData[0]=leftData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))

    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

   }
  else if(val==CHART_SCREEN_TYPE.TWO_COLUMNS){
    setrRghtConatianerState((prevState) => ({
        ...prevState,
        rightConatianerVisible:false}));

    let leftData=chartStateData[0];
    let rightData=chartStateData[1];
    let width=Math.floor((window.innerWidth-51)/2);
    let height=Math.floor((window.innerHeight - 48));
    leftData.top=0;
    leftData.left=0;
    leftData.height=height;
    leftData.width=width;
    chartStateData[0]=leftData;
    rightData.top=0;
    rightData.left=leftData.width;
    rightData.height=height;
    rightData.width=width;
    chartStateData[1]=rightData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }
  else if(val==CHART_SCREEN_TYPE.TWO_ROWS){
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible:false}));

    let topData=chartStateData[0];
    let bottomData=chartStateData[2];
    let width=Math.floor((window.innerWidth-51));
    let height=Math.floor((window.innerHeight - 48)/2);
    topData.top=0;
    topData.left=0;
    topData.height=height;
    topData.width=width;
    chartStateData[0]=topData;
    bottomData.top=topData.height;
    bottomData.left=0;
    bottomData.height=height;
    bottomData.width=width;
    chartStateData[2]=bottomData;
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }
  else if(val==CHART_SCREEN_TYPE.GRID){
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible:false}));

    let topLeftData=chartStateData[0];
    let topRightData=chartStateData[1];
    let bottomLeftData=chartStateData[2];
    let bottomRightData=chartStateData[3];
    let width=Math.floor((window.innerWidth-51)/2);
    let height=Math.floor((window.innerHeight - 48)/2);
    topLeftData.top=0;
    topLeftData.left=0;
    topLeftData.height=height;
    topLeftData.width=width;
    chartStateData[0]=topLeftData;

    topRightData.top=0;
    topRightData.left=topLeftData.width;
    topRightData.height=height;
    topRightData.width=width;
    chartStateData[1]=topRightData;
    
    bottomLeftData.top=topLeftData.height;
    bottomLeftData.left=0;
    bottomLeftData.height=height;
    bottomLeftData.width=width;
    chartStateData[2]=bottomLeftData;
    
    bottomRightData.top=topLeftData.height;
    bottomRightData.left=topLeftData.width;
    bottomRightData.height=height;
    bottomRightData.width=width;
    chartStateData[3]=bottomRightData;
    
    console.log("chartdata=",chartStateData)
    let newState=JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded:true,
      chartStateData:newState}));

  }else if (val === CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW) {
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible: false,
    }))

    let leftData = chartStateData[0]
    let rightData = chartStateData[1]
    let topThirdCol = chartStateData[4]

    let width = Math.floor((window.innerWidth - 51) / 3)
    let height = Math.floor(window.innerHeight - 48)
    // first
    leftData.top = 0
    leftData.left = 0
    leftData.height = height
    leftData.width = width
    chartStateData[0] = leftData

    rightData.top = 0
    rightData.left = leftData.width
    rightData.height = height
    rightData.width = width
    chartStateData[1] = rightData

    // third container
    topThirdCol.top = 0
    topThirdCol.left = leftData.width + rightData.width
    topThirdCol.height = height
    topThirdCol.width = width
    chartStateData[4] = topThirdCol

    console.log('chartdata=', chartStateData)
    let newState = JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded: true,
      chartStateData: newState,
    }))
  } else if (val === CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS) {
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      rightConatianerVisible: false,
    }))

    let topLeftData = chartStateData[0]
    let topRightData = chartStateData[1]
    let bottomLeftData = chartStateData[2]
    let bottomRightData = chartStateData[3]
    let topThirdCol = chartStateData[4]
    let bottomThirdCol = chartStateData[5]

    let width = Math.floor((window.innerWidth - 51) / 3)
    let height = Math.floor((window.innerHeight - 48) / 2)

    topLeftData.top = 0
    topLeftData.left = 0
    topLeftData.height = height
    topLeftData.width = width
    chartStateData[0] = topLeftData

    topRightData.top = 0
    topRightData.left = topLeftData.width
    topRightData.height = height
    topRightData.width = width
    chartStateData[1] = topRightData

    bottomLeftData.top = topLeftData.height
    bottomLeftData.left = 0
    bottomLeftData.height = height
    bottomLeftData.width = width
    chartStateData[2] = bottomLeftData

    bottomRightData.top = topLeftData.height
    bottomRightData.left = topLeftData.width
    bottomRightData.height = height
    bottomRightData.width = width
    chartStateData[3] = bottomRightData

    // last col 1st row
    topThirdCol.top = 0
    topThirdCol.left = topLeftData.width + topRightData.width
    topThirdCol.height = height
    topThirdCol.width = width
    chartStateData[4] = topThirdCol

    // last col 2nd row
    bottomThirdCol.top = topThirdCol.height
    bottomThirdCol.left = bottomLeftData.width + bottomRightData.width
    bottomThirdCol.height = height
    bottomThirdCol.width = width
    chartStateData[5] = bottomThirdCol

    console.log('chartdata=', chartStateData)
    let newState = JSON.parse(JSON.stringify(chartStateData))
    setChartContainerState((prevState) => ({
      // ...prevState,
      loaded: true,
      chartStateData: newState,
    }))
  }
}

useEffect(()=>{
  if(savedStateLoaded)
  handleChartScreenType(chartScreenType)
},[windowSize,rightConatianerVisible]);

const showSubscribeModal=(isCustom=false,msg)=>{
  if(isCustom){
    setSubscriptionPopupData({isCustom:true,msg:msg});
    setShowPopup(true);
  }else{
    setSubscriptionPopupData({isCustom:false,msg:""});
    setShowPopup(true);
  }
}

const showSubscribeProModal=(isCustom=false,msg)=>{
  if(isCustom){
    setSubscriptionPopupData({isCustom:true,msg:msg});
    setShowProPopup(true);
  }else{
    setSubscriptionPopupData({isCustom:false,msg:""});
    setShowProPopup(true);
  }
}

const openRightPanel=(bshow,metaData,historicalData,selectedIndexes,isLiveSession,isUpdateDataOnly=false)=>{
  const finalIndexes = selectedIndexes.sort((a, b) => b - a);
  console.log("live panel Market Profile:openRightPanel==> show,meta,historicalData,selectedIndex",bshow,metaData,historicalData,selectedIndexes,isLiveSession)
  if(!isUpdateDataOnly){
      if(bshow){
        console.log("live panel Market Profile:openRightPanel==> 1");
      setrRghtConatianerState((prevState) => ({
        ...prevState,
        showLive:false,
        rightConatianerVisible:bshow,
        metadata:metaData,
        histoticalData:historicalData,
        selectedIndexes:finalIndexes,
        isLiveSession:isLiveSession,
      }));
      setToggleLiveButton(!toggleLiveButton);
    }else{
      if(showLive){   //update live profile state in container
        console.log("live panel Market Profile:openRightPanel==> 2");
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          selectedIndexes:finalIndexes,
          isLiveSession:isLiveSession
        }));
      }
      else if(rightConatianerVisible){       //update live profile in container when open through context menu
        console.log("live panel Market Profile:openRightPanel==> 3");
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          // selectedIndexes:selectedIndexes,
          isLiveSession:isLiveSession
        }));
      }
      else {       //update live profile in container when open through context menu
        console.log("live panel Market Profile:openRightPanel==> 4",isLiveSession);
        setrRghtConatianerState((prevState) => ({
          ...prevState,
          // showLive:false,
          metadata:metaData,
          histoticalData:historicalData,
          selectedIndexes:finalIndexes,
          isLiveSession:isLiveSession
        }));
      }
    }
  }else{
    console.log("live panel Market Profile:openRightPanel==> 5",isLiveSession,metaData);
    if(selectedIndexes && selectedIndexes.length>0)
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      histoticalData:historicalData,
       selectedIndexes:finalIndexes,
      metadata:metaData,

    }));
    else
    setrRghtConatianerState((prevState) => ({
      ...prevState,
      histoticalData:historicalData,
      //  selectedIndexes:selectedIndexes,
       metadata:metaData,

    }));

  }
}

const closeRightPanelModal=()=>{
  setrRghtConatianerState((prevState) => ({
    ...prevState,
    showLive:false,
    selectedIndexes:[],
    rightConatianerVisible:false,
  }));
}

const openLivePanel=(isAllowed)=>{
  console.log("openlivepanel is called",isAllowed,isLiveSession)
  if(isAllowed && parentChartType==CHART_TYPE.MARKET_PROFILE){
    console.log("opeLive panel state=",rightConatianerState);
    if(histoticalData){
      let index=[];
      if(histoticalData)
      index.push(histoticalData.profilesData.length-1)

    setrRghtConatianerState((prevState) => ({
      ...prevState,
      showLive:true,
      selectedIndexes:index,
      rightConatianerVisible:true,
    }));
  }
    else{
    //   setMsgState({open:true,msg:`${TEXT_MSGS.MARKET_NOT_LIVE}`,severity:"info"});
      setToggleLiveButton(!toggleLiveButton);
     }
    }else{
      if(parentChartType==CHART_TYPE.MARKET_PROFILE)
      setMsgState({open:true,msg:`${TEXT_MSGS.LIVE_PANEL_NOT_SUPPORTED}`,severity:"info"});
      else{
      
      setMsgState({open:true,msg:`${TEXT_MSGS.LIVE_PANEL_NOT_SUPPORTED_LC}`,severity:"info"});
      }
     
      setToggleLiveButton(!toggleLiveButton);
  }
}



const resetRightPanel=()=>{
  console.log("live panel handlechange Reset right panel called")
  setrRghtConatianerState((prevState) => ({
    ...prevState,
  rightConatianerVisible:false,
  metadata:undefined,
  histoticalData:undefined,
  selectedIndexes:[],
  isLiveSession:false,
  showLive:false,
}));
}

const setLoginPopup=(val=true,msg=TEXT_MSGS.LOGIN_POPUP_MSG)=>{
  setLoginMsg(msg);
  // console.log("TEST LOGIN Market profile 1",msg)
  setShowLoginPopup(val);
}

const saveChartState=(newObject,type=undefined)=>{
  let obj=getChartSavedState(savedData,newObject,type,chartScreenType);
  console.log("ISSUEMP updated saved data=",obj);
  setSavedData(obj);
 
}

useEffect(()=>{
console.log("IssueMP 3 Reload current savedData=",savedData)
},[savedData])


const saveAsBookamrk=(name)=>{
  // console.log("GlobalBk saveAsBookamrk",name,savedData.data);
  let UUID=uuidv4();
  setTempUUID(UUID);
  let chartData={
    data:savedData.data,
    type:savedData.type   //containertype
  }
  let tempData={
    chartType:CHART_TYPE.MARKET_PROFILE,
    name:name,
    data:chartData,
    global_id:UUID,
  }
  setGlobalBookmarkData(tempData);
  console.log("GlobalBk saveAsBookamrk",name,tempData,UUID);
  executeAPI(URL.SAVE_GLOBAL_BOOKMARK,"POST",tempData);
}

const saveExistingBookamrk=()=>{
  // console.log("GlobalBk saveAsBookamrk",name,savedData.data);
 
  let chartData={
    data:savedData.data,
    type:savedData.type   //containertype
  }
  let tempData={
    chartType:CHART_TYPE.MARKET_PROFILE,
    name:globalBookmarkData.name,
    data:chartData,
    global_id:tempUUID,
  }
  
  console.log("GlobalBk saveExistingBookamrk",tempData,isDirty);
  executeAPI(URL.SAVE_GLOBAL_BOOKMARK,"POST",tempData);
  setIsDirty(false);
}
 //api response handler
 useEffect(()=>{
  if(loaded){
    console.log("Bookmark saved ========>",responseData);
    if(responseData!=null){
     
      if(responseData.result==GLOBAL_BOOKMARK_RESULTS.SUCCESS)
        setMsgState({open:true,msg:TEXT_MSGS.BOOKMARK_SAVED_SUCCESS,severity:"info"});
       if(responseData.result==GLOBAL_BOOKMARK_RESULTS.UNAUTHORIZED)
        setMsgState({open:true,msg:TEXT_MSGS.BOOKMARK_ACTION_NOT_ALLOWED,severity:"info"});
      setIsGlobalBookmark(true);
      setSearchParams({"globalBookmarkID": tempUUID})
      setHeaderData({name:globalBookmarkData.name,id:globalBookmarkData.global_id});
      
     }else if(error!==null){
         console.log("Error data=",error);
      
     
      // setMsgState({open:true,msg:errorAutoSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      if(error?.response?.status === 401 || error?.response?.status === 403){
        console.log("status received =",error?.response?.status)
        navigate(from, { replace: true });
      }else{
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
    }
    reset();
    
  }

},[loadedSave,responseData])

const cancelSaveHandler=()=>{
  setOpenBookmarkDialog(false)
}

const saveBookmark=(name)=>{
  console.log("GlobalBK Dialog save called name=",name);
  setOpenBookmarkDialog(false);
  saveAsBookamrk(name);
}

  return (
    <>

      <div style={{width:"100%",height:"100%"}}>
        {loaded?
        <>
          {/* <div style={{display:'flex'}}> */}
          {/* className="chart-container" */}
          <div className="chart-container-new" style={{height:`calc(${chartStateData[0].height}px)`, width:`calc(${chartStateData[0].width}px)` }}>
          <ChartContainer saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup}setChartLoaded={setChartLoaded} key={0} val={0} id="container_1" boundClass=".chart-container-new" setChartType={setParentChartType} resetRightPanel={resetRightPanel} setRightPanel={openRightPanel} chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal}  openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[0]} selectedIndexes={selectedIndexes} 
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}>

          </ChartContainer>
          </div>

          {chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS ||chartScreenType==CHART_SCREEN_TYPE.GRID|| chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS  ?
          <div className="chart-container-new1" style={{visibility:(chartScreenType==CHART_SCREEN_TYPE.TWO_COLUMNS ||chartScreenType==CHART_SCREEN_TYPE.GRID | chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS)?'visible':'hidden', height:`calc(${chartStateData[1].height}px)`, width:`calc(${chartStateData[1].width}px)`, marginLeft:`calc(${chartStateData[1].left}px)` }}>
          <ChartContainer  saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup} key={1} val={1} id="container_2"  boundClass=".chart-container-new1" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[1]}
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}></ChartContainer>
          </div>
           :
          <></>}

          {chartScreenType==CHART_SCREEN_TYPE.TWO_ROWS ||chartScreenType==CHART_SCREEN_TYPE.GRID ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="chart-container-new2" style={{ height:`calc(${chartStateData[2].height}px)`, width:`calc(${chartStateData[2].width}px)`, marginLeft:`calc(${chartStateData[2].left}px)`, marginTop:`calc(${chartStateData[2].top}px)` }}>
          <ChartContainer saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup} key={2} val={2} id="container_3" boundClass=".chart-container-new2" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[2]}
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}
          ></ChartContainer>
          </div>
          :
          <></>}
          {chartScreenType==CHART_SCREEN_TYPE.GRID ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="chart-container-new3" style={{ height:`calc(${chartStateData[3].height}px)`, width:`calc(${chartStateData[3].width}px)`, marginLeft:`calc(${chartStateData[3].left}px)`, marginTop:`calc(${chartStateData[3].top}px)` }}>
          <ChartContainer saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup} key={3} val={3} id="container_4" boundClass=".chart-container-new3" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[3]}
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}
          ></ChartContainer>
          </div>
          :
          <></>}
          {chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW ||chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="chart-container-new4" style={{ height:`calc(${chartStateData[4].height}px)`, width:`calc(${chartStateData[4].width}px)`, marginLeft:`calc(${chartStateData[4].left}px)`, marginTop:`calc(${chartStateData[4].top}px)` }}>
          <ChartContainer saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup} key={4} val={4} id="container_5" boundClass=".chart-container-new4" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[4]}
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}></ChartContainer>
          </div>
          :
          <></>}
          {chartScreenType == CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS?
          <div className="chart-container-new5" style={{ height:`calc(${chartStateData[5].height}px)`, width:`calc(${chartStateData[5].width}px)`, marginLeft:`calc(${chartStateData[5].left}px)`, marginTop:`calc(${chartStateData[5].top}px)` }}>
          <ChartContainer saveChartState={saveChartState}  tabData={savedData} showLoginPopup={setLoginPopup} key={5} val={5} id="container_6" boundClass=".chart-container-new5" resetRightPanel={resetRightPanel} setRightPanel={openRightPanel}chartScreenType={chartScreenType} basicUser={isBasicUser} 
          openSubscribeModal={showSubscribeModal} openSubscribePropModal={showSubscribeProModal} proUser={isProUser} chartStateData={chartStateData[5]}
          isGlobalBookmark={isGlobalBookmark}
          globalBookmarkData={globalBookmarkData}
          setIsDirty={setIsDirty}></ChartContainer>
          </div>
          :
          <></>}
           
           {/* <MPChartRightPanelContainer></MPChartRightPanelContainer> */}
            
           {rightConatianerVisible && chartScreenType==CHART_SCREEN_TYPE.FULL_CHART? 
            <MPChartRightPanelContainer closeModal={closeRightPanelModal}
              metadata={metadata}
              historicalData={histoticalData}
              selectedIndexes={selectedIndexes}
              isLiveSession={isLiveSession}
              isBasicUser={isBasicUser}
              proUser={isProUser}
              pro2User={isPro2User}
              showLive={showLive}
              localeString={userSettings.data.number_format_locale}
            />
            :
            <></>
           }

          <MPChartRightPanel isLiveEnabled={isLiveEnabled} toggleLiveButton={toggleLiveButton}resetRightPanel={resetRightPanel}  closeModal={closeRightPanelModal} showLive={showLive} openLivePanel={openLivePanel} isBasicUser={isBasicUser} proUser={isProUser} openSubscribeModal={showSubscribeProModal} chartScreenType={chartScreenType}  basicUser={isBasicUser} setChartScreenType={handleChartScreenType}
          isGlobalBookmark={isGlobalBookmark}
          saveGlobalBookmark={saveGlobalBookmark}
          isDirty={isDirty}
          globalBookmarkData={globalBookmarkData}
              savedData={savedData}/>
          
          </>:
          <></>
          }
      </div>
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
      {showPopup?
         <SubscriptionPopup open={showPopup} data={subscriptionPopupData} proMessage={false} onClose={()=>setShowPopup(false)}></SubscriptionPopup>  :
         <></>
      }
      {showProPopup?
         <SubscriptionPopup open={showProPopup}  data={subscriptionPopupData} proMessage={true} onClose={()=>setShowProPopup(false)}></SubscriptionPopup>  :
         <></>
      }
      {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>
      }
       {openBookmarkDialog?
     <SaveGlobalBookmarkDialog open={true} cancelHandler={cancelSaveHandler} saveHandler={saveBookmark} ></SaveGlobalBookmarkDialog>    :
     <></>
    }
    </>
       
   
   
  )
}

export default MarketProfileCharts

