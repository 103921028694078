import React, { useEffect, useState,useRef } from "react";
import { Box, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChromePicker } from 'react-color';
import { hexToRGBA } from "../../../common/utility/helperFunc";
import ColorPicker from "./ColorPicker";
import { DRAWING_TOOL_WIDTH_LIST } from "../../../common/utility/constant";

const LineToolbar = ({ dragLine, onUpdateLine, onDeleteLine, id }) => {
  const [selectedLine, setSelectedLine] = useState(dragLine);
  const [showPicker, setShowPicker] = useState(false);
  const getPopupContainer = (trigger) => trigger.parentNode;
  const pickerRef = useRef(null);
  const [colorPickerPosition, setColorPickerPosition] = useState({ top: 0, left: 0 });
  const [text, setText] = useState(dragLine.text?dragLine.text:"");
  const [debouncedText, setDebouncedText] =  useState(dragLine.text?dragLine.text:"");
  const textFieldRef = useRef(null)



  useEffect(() => {
    console.log("LineToolbar useEffect 0 dragline=", dragLine);
    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          setShowPicker(false); // Close the color picker on Escape key
        }
      };
  
      const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
          setShowPicker(false); // Close the color picker on outside click
        }
      };
  
      // Add event listeners
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleClickOutside);

    //   const timeout = setTimeout(() => {
    //     if (textFieldRef.current) {
    //         textFieldRef.current.focus();
    //       }

    //   }, 100);
     
    //   requestAnimationFrame(() => {
    //     textFieldRef.current?.focus();
    //   });
  
      // Cleanup event listeners
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("mousedown", handleClickOutside);
        // clearTimeout(timeout);
      };
  }, []);

  useEffect(() => {
    console.log("LineToolbar useEffect 1 dragline=", dragLine);
    setSelectedLine(dragLine);
  }, [dragLine]);

  useEffect(() => {
    console.log("LineToolbar selectedLine=", selectedLine);
  }, [selectedLine]);

  const handlePickerClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    console.log("LineToolbar rect and window=",rect.bottom,rect.left,window.scrollY)
    setColorPickerPosition({ top: 48, left: 0 });
    setShowPicker(!showPicker); // Toggle the color picker visibility
  };


  const handleChange = (event) => {
    setText(event.target.value);
    // onUpdateLine({ ...selectedLine, text:event.target.value });
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedText(text);
      onUpdateLine({ ...selectedLine, text:text });
    }, 100); // Delay of 500ms

    return () => clearTimeout(timer); // Cleanup on unmount or text change
  }, [text]);

  return (
    <Box
      id={`toolbar${id}`}
      sx={{
        position: "absolute",
        left: "50%",
        top: 54,
        padding:0,
        transform: "translateX(-50%)",
        backgroundColor: "#285fbd",
        border: "1px solid #ccc",
        // padding: 2,
        borderRadius: 2,
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        zIndex: 9999,
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        height: "44px", // Set the desired height
        width: "480px", // Set the desired width
      }}
    >
      {selectedLine ? (
        <>
          <Box display="flex" flexDirection="column" alignItems="center">
           
            <div
          style={{
            width: '30px',
            minWidth:"30px",
            height: '10px',
            // borderRadius: '50%',
            backgroundColor: selectedLine.color,
            cursor: 'pointer',
            marginLeft:"8px",
            marginTop:"2px",
            marginRight:"6px"
          }}
          onClick={handlePickerClick}
        />
            
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
           
            <Select
              value={selectedLine.width}
              onChange={(e) =>
                onUpdateLine({ ...selectedLine, width: Number(e.target.value) })
              }
              size="small"
              sx={{textAlign:"center",width:70,height:30,paddingTop:"4px"}}
           
            >
              {DRAWING_TOOL_WIDTH_LIST.map((w) => (
                <MenuItem key={w} value={w}>
                  {w}px
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            
            <Select
              value={selectedLine.style}
              onChange={(e) =>
                onUpdateLine({ ...selectedLine, style: e.target.value })
              }
              size="small"
              sx={{textAlign:"center",width:90,height:30,paddingTop:"4px"}}
            >
              <MenuItem value="solid">Solid</MenuItem>
              <MenuItem value="dotted">Dotted</MenuItem>
              <MenuItem value="dashed">Dashed</MenuItem>
            </Select>
          </Box>

          <TextField
      label="Add Description"
      size="small"
      variant="outlined"
      value={text}
      onChange={handleChange}
      inputRef={textFieldRef} 
      fullWidth
      inputProps={{ maxLength: 40, style: { padding: "5px 10px", height: "30px" } }} // Adjust padding & height
      sx={{
        "& .MuiInputBase-root": {
          height: 30, // Set height
          display: "flex",
          alignItems: "center", // Ensure text is centered
        },
        "& .MuiInputLabel-root": {
          top: "-5px", // Adjust label position
        },
        "& .MuiOutlinedInput-input": {
          padding: "5px 10px", // Adjust text padding
        },
      }}
    />
          <IconButton
            onClick={onDeleteLine}
            sx={{ color: "red", mt: 0,mr:1 }}
            title="Delete"
          >
            <DeleteIcon />
          </IconButton>

        </>
      ) : (
        <Typography variant="body2" sx={{ color: "#fff" }}>
          Select a line to edit.
        </Typography>
      )}
      {showPicker?
      <div ref={pickerRef}>
                    <ColorPicker
            position={colorPickerPosition}
            color={selectedLine.color}
            onChange={(color) => {
              onUpdateLine({ ...selectedLine, color: color.hex });
            //   setShowPicker(false);
            }}
            onClose={() => setShowPicker(false)}
          />
          </div>
:
            <></>
}
    </Box>
  );
};

export default LineToolbar;
