import React, { useState, useRef } from "react";
import { IconButton, Tooltip, Popper, Paper, List, ListItem, ListItemText, ClickAwayListener, Divider, Typography } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmarks";
import { useNavigate } from "react-router-dom";
import { getBasePathBasedOnChartType } from "../../common/utility/helperFunc";
import EditIcon from '@mui/icons-material/EditNote';

const BookmarkComponent = ({ bookmarks, onBookmarkClick }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null); // Reference to IconButton

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleManageClick = () => {
        navigate("/global-bookmarks"); // Navigate to global bookmarks page
        setOpen(false);
    };

    const openBookmark = (event, item) => {
        setOpen(false);
        const basePath = getBasePathBasedOnChartType(item.chartType)
        console.log("GlobalBK openBookmark item=", item, item.chartType, item.id, basePath)

        const url = `${process.env.REACT_APP_URL}${basePath}?globalBookmarkID=${item.id}`

        if (event.ctrlKey || event.metaKey) {
            window.open(`${url}`, '_blank', 'noreferrer');
        } else {
            window.location.href = url;
        }

    }
    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleToggle}
                sx={{ mr: 1 }}
                size="small"
                edge="start"
                aria-label="bookmark"
            >
                <Tooltip title="Global Bookmarks">
                    <BookmarkIcon sx={{ color: "primaryTheme.shade01", width: "24px", height: "24px" }} />
                </Tooltip>
            </IconButton>

            {/* Popper for showing the list below the button */}
            {/* <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" style={{ zIndex: 1300,mr:-8 }}> */}
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-end"
                style={{ zIndex: 1300, transform: "translateX(-20px)" }} // Moves it 20px left
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper
                        elevation={3}
                        sx={{
                            pl: 1,
                            pr: 1,
                            pb: 1,
                            minWidth: 200,
                            maxHeight: 340,  // Limit height to 400px
                            overflowY: "auto" // Enable scrolling when content overflows
                        }}
                    >
                        <List>
                            {/* Manage Bookmark Button */}
                            <ListItem
                                button
                                onClick={handleManageClick}
                                sx={{
                                    fontWeight: "bold",
                                    color: "primary.main",
                                    position: "sticky",
                                    top: 0,
                                    backgroundColor: "#0d203f",
                                    zIndex: 1
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold" }}>
                                            Global Bookmarks <EditIcon sx={{color: "#ffffff",ml:0.4,mt:0,mb:-0.3,width:18,height:18}}></EditIcon>
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <Divider /> {/* Adds a separator */}
                            {bookmarks.length > 0 ? (
                                bookmarks.map((bookmark) => (
                                    <ListItem button key={bookmark.id} onClick={(event) => openBookmark(event, bookmark)}>
                                        <ListItemText primary={
                                            <Typography variant="body2" sx={{ fontSize: "12px" }}>
                                                {bookmark.name}
                                            </Typography>
                                        } />
                                    </ListItem>
                                ))
                            ) 
                            : (
                                <></>
                                // <ListItem>
                                //     {/* <ListItemText primary="No bookmarks available" /> */}
                                // </ListItem>
                            )
                            }
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export default BookmarkComponent;
