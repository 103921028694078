
import React,{useContext,useEffect,useState} from 'react'
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import { useNavigate, useLocation } from 'react-router-dom';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION,SNACKBAR_AUTO_HIDE_DURATION_SHORT } from '../../common/utility/constant';
import { Snackbar,Alert,CircularProgress,InputLabel,Switch,Radio,RadioGroup,FormControlLabel,FormLabel } from '@mui/material';
import {Grid, Box,Typography,Select,ListSubheader,TextField,InputAdornment,FormControl,MenuItem,Popover,Divider} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SettingsFooter from './components/settings_footer';
import { findIndex } from '@amcharts/amcharts5/.internal/core/util/Array';
import { VP_TYPE,VA_TYPE, CHART_TYPE, CHART_SCREEN_TYPE } from '../../common/utility/mp_chart_constants';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme} from '@mui/material/styles';
import { BOOKMARK_CATEGORY_AUTOSAVE } from '../../common/utility/constant';
import LoginModal from './components/login_popups';
import { getBasePathBasedOnChartType } from '../../common/utility/helperFunc';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from "@mui/material";
import { index } from 'd3';
import ConfirmActionDialog from './components/confirm_dialog';
import EditIcon from '@mui/icons-material/Edit';
import SaveGlobalBookmarkDialog from './components/create_global_bookmark_dialog';

const ROW_SIZE=1;
const COL_SIZE=3;

const GlobalBookmarks = () => {
  const theme=useTheme();
  const from = "/login";
  const navigate = useNavigate(); 
  const [searchText, setSearchText] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;
  const [cancel,responseData, error, loaded, reset,executeAPI ] = useAxiosWithAuth();
  const [cancelDelete,responseDataDelete, errorDelete, loadedDelete, resetDelete,executeAPIDelete ] = useAxiosWithAuth();
  const [cancelEdit,responseDataEdit, errorEdit, loadedEdit, resetEdit,executeAPIEdit ] = useAxiosWithAuth();
  const [bookmarkState, setBookmarkState]=useState({
    bookmarkData:undefined,
    displayData:undefined,
    isLoaded:false
  });
  const [deletedId,setDeletedId]=useState();

  const {bookmarkData,displayData,isLoaded}=bookmarkState;
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const [msgState, setMsgState] = useState({
    open: false,
    msg:"" ,
    severity:"info"
  });
  const [showLoginPopup,setShowLoginPopup]=useState(false);
  const [loginMsg,setLoginMsg]=useState(TEXT_MSGS.LOGIN_POPUP_MSG);
  const [openDialog,setOpenDialog]=useState(false);
  const [selectedBookmark,setSelectedBookmark]=useState();
  const [editData,setEditData]=useState();
  const [openBookmarkDialog,setOpenBookmarkDialog]=useState(false);
  
  /**
 * autoclosing of the snackbar msg bar 
 */ 
   const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};
  const { open, msg, severity } = msgState;

  useEffect(() => {
    document.title = "Global Bookmarks";
  }, []);

  //load bookmarks
  useEffect(()=>{
    executeAPI(URL.GET_ALL_GLOBAL_BOOKMARK,"GET",{})
    // executeAPITest(URL.GET_GLOBAL_BOOKMARK+"?global_id=2acfa596-1385-4497-8167-e88fbfdc9ec3","GET",{})
  },[])

  const getRowCount=(chartType)=>{
    if(chartType==CHART_SCREEN_TYPE.FULL_CHART){
      return 1;
    }else if(chartType==CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW){
      return 1;
    }else if(chartType==CHART_SCREEN_TYPE.TWO_COLUMNS){
      return 1;
    }else if(chartType==CHART_SCREEN_TYPE.GRID){
      return 2;
    }
    else if(chartType==CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS){
      return 2;
    }
    else if(chartType==CHART_SCREEN_TYPE.TWO_ROWS){
      return 2;
    }
  }

  const getColumnCount=(chartType)=>{
    if(chartType==CHART_SCREEN_TYPE.FULL_CHART){
      return 1;
    }else if(chartType==CHART_SCREEN_TYPE.THREE_COLUMNS_ONE_ROW){
      return 3;
    }else if(chartType==CHART_SCREEN_TYPE.TWO_COLUMNS){
      return 2;
    }else if(chartType==CHART_SCREEN_TYPE.GRID){
      return 2;
    }
    else if(chartType==CHART_SCREEN_TYPE.THREE_COLUMNS_TWO_ROWS){
      return 3;
    }
    else if(chartType==CHART_SCREEN_TYPE.TWO_ROWS){
      return 1;
    }
  }
   //api response handler
   useEffect(()=>{
    if(loaded){
      // console.log("Bookmark list ========>",responseData.data);
      if(responseData!=null){
        let data=responseData.data.filter(item => item.category!=BOOKMARK_CATEGORY_AUTOSAVE);
        const formattedData = data.map((bookmark) => ({
          ...bookmark, // Keep the original object
         rowCount:getRowCount(bookmark.data.type),
         colCount:getColumnCount(bookmark.data.type)

      }));
      console.log("Bookmark formattedData ========>",formattedData);
        // let data=responseData.data;
        setBookmarkState((prevState)=>({
          ...prevState,
          bookmarkData:formattedData,
          displayData:formattedData,
          isLoaded:true
        }))
       }else if(error!==null){
        setBookmarkState((prevState)=>({
          ...prevState,
          isLoaded:true
        }))

        console.log("Error data=",error);
        
       
        // setMsgState({open:true,msg:errorAutoSave?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        if(error?.response?.status === 401 || error?.response?.status === 403){
          console.log("status received =",error?.response?.status)
          navigate(from, { replace: true });
        }else{
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
      }
      reset();
      
    }

  },[loaded,responseData])

  const deleteBookmark=(item)=>{
    setSelectedBookmark(item)
   setOpenDialog(true);
   
  }

  const deleteBookmarkConfirm=()=>{
    setDeletedId(selectedBookmark.id);
    setOpenDialog(false);
    executeAPIDelete(`${URL.DELETE_GLOBAL_BOOKMARK}?global_id=${selectedBookmark.id}`,"DELETE",{})
  }

  const cancelBookmarkDelete=()=>{
    setOpenDialog(false);
  }

  const editBookmark=(item)=>{
    console.log("editBookmark item=",item);
    setEditData(item);
    setOpenBookmarkDialog(true);

  }

  const cancelSaveHandler=()=>{
    setOpenBookmarkDialog(false)
  }

  const saveBookmark=(name)=>{
    console.log("GlobalBK Dialog save called name=",name);
    setOpenBookmarkDialog(false);
    // saveAsBookamrk(name);
    let data={
      chartType:editData.chartType,
      name:name,
      data:editData.data,
      id:editData.id,
      global_id:editData.id
    }
    setEditData(data);
    executeAPIEdit(URL.SAVE_GLOBAL_BOOKMARK,"POST",data);
  }

    //delete api response handler
    useEffect(()=>{
      if(loadedEdit){
        console.log("Delete response ========>",responseDataEdit);
        if(responseDataEdit!=null){
          setMsgState({open:true,msg:`${TEXT_MSGS.BOOKMARK_EDITED_SUCCESSFULLY}`,severity:"success"});
          let updatedData = bookmarkData.map(item =>
            item.id === editData.id ? { ...item, name: editData.name } : item
          );
          setBookmarkState((prevState)=>({
            ...prevState,
            bookmarkData:updatedData,
            displayData:updatedData,
            isLoaded:true
          }))
          setDeletedId(undefined);
         
         }else if(errorEdit!==null){
          setBookmarkState((prevState)=>({
            ...prevState,
            isLoaded:true
          }))
          setEditData(undefined);
  
         
          setMsgState({open:true,msg:errorEdit?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          if(errorEdit?.response?.status === 401 || errorEdit?.response?.status === 403){
            console.log("status received =",errorEdit?.response?.status)
            // navigate(from, { replace: true });
            setLoginMsg(errorEdit?.response?.data?.message);
            setShowLoginPopup(true);
          }else
          setMsgState({open:true,msg:errorEdit?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetEdit();
        
      }
  
    },[loadedEdit,responseDataEdit])
  //delete api response handler
  useEffect(()=>{
    if(loadedDelete){
      console.log("Delete response ========>",responseDataDelete);
      if(responseDataDelete!=null){
        setMsgState({open:true,msg:`${TEXT_MSGS.BOOKMARK_DELETED_SUCCESSFULLY}`,severity:"success"});
        let updatedData= bookmarkData.filter(item => item.id!=deletedId);
        // localStorage.setItem("bookmark_list",JSON.stringify(updatedData));
        setBookmarkState((prevState)=>({
          ...prevState,
          bookmarkData:updatedData,
          displayData:updatedData,
          isLoaded:true
        }))
        setDeletedId(undefined);
       
       }else if(errorDelete!==null){
        setBookmarkState((prevState)=>({
          ...prevState,
          isLoaded:true
        }))
        setDeletedId(undefined);

       
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        if(errorDelete?.response?.status === 401 || errorDelete?.response?.status === 403){
          console.log("status received =",errorDelete?.response?.status)
          // navigate(from, { replace: true });
          setLoginMsg(errorDelete?.response?.data?.message);
          setShowLoginPopup(true);
        }else
        setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetDelete();
      
    }

  },[loadedDelete,responseDataDelete])


  const copyURL =(event,item)=>{
    const basePath=getBasePathBasedOnChartType(item.chartType)
    const url=`${process.env.REACT_APP_URL}${basePath}?globalBookmarkID=${item.id}`
    navigator.clipboard.writeText(url);
    setAnchorEl(event.currentTarget);
  }

  // const openBookmark=(event,id)=>{
  //     const url=`${process.env.REACT_APP_URL}market-profile?bookmarkID=${id}`
  //     window.open( `${url}`, '_blank', 'noreferrer');
    
  // }


  const openBookmark=(event,item)=>{
    const basePath=getBasePathBasedOnChartType(item.chartType)
    console.log("GlobalBK openBookmark item=",item,item.chartType, item.id,basePath)
   
    const url=`${process.env.REACT_APP_URL}${basePath}?globalBookmarkID=${item.id}`
    window.open( `${url}`, '_blank', 'noreferrer');
  
}
const getDisplayName=(item,rowIndex,colIndex)=>{
let temp="";
let index=rowIndex+colIndex;
console.log("Test",rowIndex,colIndex,index,item.data.data[index],item.data.data[index].symbol,item.data.data[index].instrument)
if(item.data.data[index].type==CHART_TYPE.LINE_CHART){
  temp="CC: "+item.data.data[index].data.symbol
}else if(item.data.data[index].type==CHART_TYPE.MARKET_PROFILE){
  temp="MP: "+item.data.data[index].data.data.instrument
}
else if(item.data.data[index].type==CHART_TYPE.OF_CHART){
  temp="OF: "+item.data.data[index].instrument
}else{
  temp="NA"
}
return temp;
}

  return (
   <>

   {isLoaded?
   <Box display="flex" flexDirection="column" sx={{m:4}}>
    <Typography sx={{mt:0,fontSize:20}}>Global Bookmarks</Typography>
   
    {bookmarkData.length>0?
    <Box sx={{mt:4}}>
      <Box display='flex' sx={{mb:2}}>
           <Typography sx={{mt:0,fontSize:18,width:400}}>Name</Typography>
           <Typography sx={{mt:0,ml:0,fontSize:18,width:240}}>Page Layout</Typography>
           <Typography sx={{mt:0,ml:6,fontSize:18,width:160,paddingTop:"0px"}}>Last Updated</Typography>
           </Box>
       {displayData.map((item,index) => (
        <Box display='flex' sx={{height:50*item.rowCount,borderBottom:"1px solid #555",mt:2}}>
          <Box sx={{width:200}}>
           <Typography noWrap sx={{mt:0,fontSize:16,width:180}}>{item.name}</Typography>
           {/* <Box display='flex'>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>Instrument:&nbsp;{item.data.instrument},&nbsp;</Typography>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>Time Frame:&nbsp;{item.data.tf},&nbsp;</Typography>
            <Typography sx={{mt:1,fontSize:12,color:"#eee"}}>TPO:&nbsp;{item.data.tpo}</Typography>
           </Box> */}
           </Box>
           <Box sx={{width:440,ml:2}}>
           {/* <Typography noWrap sx={{mt:1,fontSize:16,width:240}}>{item.category}</Typography> */}
           <TableContainer component={Paper} sx={{ maxWidth: 600, margin: "auto", border: "1px solid black" }}>
      <Table sx={{ borderCollapse: "collapse" }}>
        <TableBody>
          {Array.from({ length: item.rowCount }).map((_, rowIndex) => (
            <TableRow key={rowIndex} sx={{ borderBottom: "1px solid black" }}>
              {Array.from({ length: item.colCount }).map((_, colIndex) => (
                <TableCell
                  key={colIndex}
                  align="center"
                  sx={{
                    border: "1px solid #96b4e9",
                    padding: "8px",
                    textAlign: "center",
                    fontSize: "12px", 
                  }}
                >
                 
                  {getDisplayName(item,rowIndex,colIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
           </Box>
           <Box sx={{width:160,ml:2}}>
           <Typography sx={{mt:1,ml:1,fontSize:14,width:160}}>{item.last_saved}</Typography>
           </Box>
           <EditIcon  onClick={()=>editBookmark(item)} sx={{color: "#ffffff",cursor:'pointer',ml:4,mt:0,width:20,height:20,mt:1}}></EditIcon>
           <DeleteIcon onClick={()=>deleteBookmark(item)} sx={{color: "#e24747",cursor:'pointer',ml:4,mt:1}}></DeleteIcon>
           <Tooltip title="Open in new tab">
           <Button onClick={(event)=>openBookmark(event,item)} variant='outlined' sx={{ml:2,mr:2,marginTop:"0px",color:"#fff",height:40,width:110}}>Open</Button>
           </Tooltip>
           <Button onClick={(event)=>copyURL(event,item)} variant='outlined' sx={{ml:2,mr:2,marginTop:"0px",color:"#fff",height:40,width:110}}>Copy URL</Button>
           <Popover  id={id} open={openPopover} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{vertical: 'top',horizontal: 'center'}}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
      >
        <Typography sx={{ p: 2 }}>URL copied to clipboard.</Typography>
      </Popover>

      <Divider sx={{borderBottomWidth:1,borderColor:"#888",mb:1,mt:1}}></Divider>
        </Box>            
                         
       ))}
    </Box>:
    <Typography sx={{mt:4,fontSize:16}}>There is no bookmark present.</Typography>
    }
    
   </Box>
   :
      <div  style={{textAlign:"center", width:"100%"}}><CircularProgress sx={{marginTop:"10%"}}/>
      </div>} 

    <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}  open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION_SHORT} >
          <Alert  severity={severity} sx={{marginTop:"48px", width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>  
       {showLoginPopup?
         <LoginModal open={true} onClose={()=>setShowLoginPopup(false)} msg={loginMsg}></LoginModal>  :
         <></>}   
          {openDialog?
      <ConfirmActionDialog open={true} cancelHandler={cancelBookmarkDelete} saveHandler={deleteBookmarkConfirm} 
          title={TEXT_MSGS.DELETE_BOOKMARK_TITLE} description={TEXT_MSGS.DELETE_BOOKMARK_MSG}></ConfirmActionDialog>    :
      <></>
      }  
       {openBookmarkDialog?
     <SaveGlobalBookmarkDialog title={"Rename Bookmark"} name={editData.name}open={true} cancelHandler={cancelSaveHandler} saveHandler={saveBookmark} ></SaveGlobalBookmarkDialog>    :
     <></>
    }
   </>
  )
}

export default GlobalBookmarks
