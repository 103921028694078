import React, { useState, useEffect, useMemo } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import { TRADING_HOURS } from "../../../common/utility/constant";

dayjs.extend(customParseFormat);
dayjs.extend(timezone);

const disabledDateFactory = (dates) => {
  // For date mode: disable if the full date string is not in the allowed dates.
  const disableSpecificDate = (current) => {
    if (!current) return false;
    const formatted = current.format("YYYY-MM-DD");
    return !dates.includes(formatted);
  };

  // For month mode: disable the entire month if no date in that month is available.
  const disableMonth = (current) => {
    if (!current) return false;
    const startOfMonth = current.startOf("month");
    const endOfMonth = current.endOf("month");
    let hasValidDay = false;
    let day = startOfMonth;
    while (day.isBefore(endOfMonth) || day.isSame(endOfMonth, "day")) {
      const formatted = day.format("YYYY-MM-DD");
      if (dates.includes(formatted)) {
        hasValidDay = true;
        break;
      }
      day = day.add(1, "day");
    }
    return !hasValidDay;
  };

  // For "year" type: enable the year if at least one month in that year has an available day
  const disableYear = (current) => {
    if (!current) return false;
    const startOfYear = current.startOf("year");
    const endOfYear = current.endOf("year");
    let hasAvailableMonth = false;
    let month = startOfYear;

    // Iterate month-by-month in the year
    while (month.isBefore(endOfYear) || month.isSame(endOfYear, "month")) {
      // Check if this month is enabled by our month-level logic
      if (!disableMonth(month)) {
        hasAvailableMonth = true;
        break;
      }
      month = month.add(1, "month");
    }
    return !hasAvailableMonth;
  };

  // Return a function that uses month logic if info.type is "month", else date logic.
  return (current, info) => {
    if (info?.type === "year") {
      return disableYear(current);
    }
    if (info?.type === "month") {
      return disableMonth(current);
    }
    return disableSpecificDate(current);
  };
};

const TradeDatesPicker = (props) => {
  const [tradeDates, setTradeDates] = useState(props.tradeDates || []);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(props.isDatePickerOpen);
  const [liveState, setLiveState] = useState({
    isLive: false,
    liveDate: null,
    liveTime: undefined,
    ...props.liveState,
  });
  const [selectedDate, setSelectedDate] = useState(props.selectedDate || null);
  const [okPressed, setOKPressed] = useState(false);

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  // Handle DatePicker open/close state
  const handleDatePickerOpenChange = (open) => {
    console.log("EnterKey DatePicker open state changed:", open);
    setIsDatePickerOpen(open);
    props.setIsDatePickerOpen(open);

    if (open) {
      setTimeout(() => {
        const overlay = document.querySelector(".ant-picker-dropdown");
        if (overlay) {
          overlay.addEventListener("keydown", handleKeyDown);
        }
      }, 0);
    } else {
      if (!okPressed) setSelectedDate(null);
      const overlay = document.querySelector(".ant-picker-dropdown");
      if (overlay) {
        overlay.removeEventListener("keydown", handleKeyDown);
      }
    }
  };

  // Update liveState when DatePicker closes
  useEffect(() => {
    if (!isDatePickerOpen) {
      setLiveState({
        isLive: props.liveState.isLive,
        liveDate: props.liveState.liveDate || null,
        liveTime: props.liveState.liveTime,
      });
    }
  }, [props.liveState, isDatePickerOpen]);

  // Create a new trade dates array based on tradeDates and liveState.
  const updatedTradeDates = useMemo(() => {
    let dates = [...tradeDates];
    if (liveState.isLive && liveState.liveDate && liveState.liveTime) {
      const livedate = dayjs(liveState.liveDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      if (!dates.includes(livedate)) {
        dates.push(livedate);
      }
    }
    return dates;
  }, [tradeDates, liveState]);

  // Memoized disabled date function using updatedTradeDates.
  const disabledDate = useMemo(
    () => disabledDateFactory(updatedTradeDates),
    [updatedTradeDates]
  );

  // Memoized disabled time function remains unchanged.
  const memoizedDisabledDateTimeNew = useMemo(() => {
    const targetDate = liveState.isLive && liveState.liveDate && liveState.liveTime
      ? liveState.liveDate
      : null;
    const lastTime = liveState.isLive && liveState.liveDate && liveState.liveTime
      ? liveState.liveTime
      : null;

    return (selectedDate) => {
      const selectedDayjs = dayjs(selectedDate);
      const targetDayjs = targetDate ? dayjs(targetDate, "DD-MM-YYYY") : null;

      return {
        disabledHours: () => {
          if (targetDayjs && selectedDayjs.isSame(targetDayjs, "day")) {
            if (lastTime) {
              const [lastHour] = lastTime.split(":").map(Number);
              return Array.from({ length: 24 }, (_, i) => i).filter(
                (hour) => hour < TRADING_HOURS.START_HOUR || hour > lastHour
              );
            }
          }
          return Array.from({ length: 24 }, (_, i) => i).filter(
            (hour) => hour < TRADING_HOURS.START_HOUR || hour > TRADING_HOURS.END_HOUR
          );
        },
        disabledMinutes: (selectedHour) => {
          if (targetDayjs && selectedDayjs.isSame(targetDayjs, "day")) {
            if (lastTime) {
              const [lastHour, lastMinute] = lastTime.split(":").map(Number);
              if (selectedHour === lastHour) {
                return Array.from({ length: 60 }, (_, i) => i).filter(
                  (minute) => minute > lastMinute
                );
              }
            }
          }
          if (selectedHour === TRADING_HOURS.START_HOUR) {
            return Array.from({ length: 60 }, (_, i) => i).filter(
              (minute) => minute < TRADING_HOURS.START_MINUTE
            );
          }
          if (selectedHour === TRADING_HOURS.END_HOUR) {
            return Array.from({ length: 60 }, (_, i) => i).filter(
              (minute) => minute > TRADING_HOURS.END_MINUTE
            );
          }
          return [];
        },
        disabledSeconds: () => [],
      };
    };
  }, [liveState]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("Selected date:", date ? date.format("DD-MM-YYYY HH:mm") : null);
  };

  const handleCalendarChange = (dates) => {
    console.log("Calendar changed:", dates, selectedDate);
    if (dates) {
      const newDate = dayjs(dates);
      if (!selectedDate || !newDate.isSame(selectedDate, "date")) {
        setSelectedDate(newDate.hour(TRADING_HOURS.START_HOUR).minute(TRADING_HOURS.START_MINUTE));
      }
    }
  };

  const handleOk = (date) => {
    console.log("Date selected on OK:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    if (selectedDate) {
      setOKPressed(true);
      props.onChange(date);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const okButton = document.querySelector(".ant-picker-ok > button");
      if (okButton) {
        setOKPressed(true);
        okButton.click();
      }
    }
  };

  return (
    <DatePicker
      allowClear={false}
      onOpenChange={handleDatePickerOpenChange}
      format="DD-MM-YYYY HH:mm"
      showTime={{
        format: "HH:mm",
        use12Hours: false,
        defaultValue: dayjs().hour(9).minute(15),
      }}
      disabledDate={disabledDate}
      disabledTime={memoizedDisabledDateTimeNew}
      placeholder="Select date and time"
      onChange={handleDateChange}
      onCalendarChange={handleCalendarChange}
      onOk={handleOk}
      value={selectedDate}
      style={{
        border: "1px solid #415e81",
        background: "#022D5A",
        borderRadius: "4px",
        width: 180,
        height: 28,
        marginLeft: 36,
      }}
    />
  );
};

export default TradeDatesPicker;
